import EffectUtility from '../../utils/EffectUtility';
import BaseResponse from "../../models/BaseResponse";
import BaseRequestParams from "../../models/BaseRequestParams";
import {ApiEndpoint} from "../../assets/constants/ApiEndpoint";
import BaseReqParamsForBlob from '../../models/BaseReqParamsForBlob';


export default class BloodSamplesEffect {

    static async _requestGetAllSamples(body) {
        const endPoint = ApiEndpoint.bloodSamples.getAll;
        return EffectUtility._postToModel(BaseResponse, endPoint, new BaseRequestParams(body));
    }

    static async _requestGetAllSampleById(body) {
        const endPoint = ApiEndpoint.bloodSamples.getById;
        return EffectUtility._postToModel(BaseResponse, endPoint, new BaseRequestParams(body));
    }

    static async _requestCreateBloodSamples(body) {
        const endPoint = ApiEndpoint.bloodSamples.createNew;
        return EffectUtility._postToModel(BaseResponse, endPoint, new BaseRequestParams(body));
    }

    static async _requestUpdateBloodSamples(body) {
        const endPoint = ApiEndpoint.bloodSamples.update;
        return EffectUtility._postToModel(BaseResponse, endPoint, new BaseRequestParams(body));
    }

    static async _requestDeleteBloodSamples(body) {
        const endPoint = ApiEndpoint.bloodSamples.delete;
        return EffectUtility._postToModel(BaseResponse, endPoint, new BaseRequestParams(body));
    }

    static async _requestGetSamplesDataOverview(body) {
        const endPoint = ApiEndpoint.bloodSamples.getDataOverview;
        return EffectUtility._postToModel(BaseResponse, endPoint, new BaseRequestParams(body));
    }

    static async _requestGetSamplesExcelFile(body) {
        const endPoint = ApiEndpoint.bloodSamples.downloadExcel;
        return EffectUtility._postToModel(BaseResponse, endPoint, new BaseReqParamsForBlob(body));
    }

}
