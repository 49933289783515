import React, { useEffect, useState } from "react";
import { CardBasic } from "../components/misc/card/CardBasic";
import TopNavBar from "../components/navigation/TopBar";
import BloodSamplesTable from "../tables/BloodSamplesTable";
import './pagestyle.css';
import { Button, Col, Collapse, Input, Row, Select } from "antd";
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import '../components/components.css';
import { DatePicker } from "antd";
import CalendarTwoTone from '@ant-design/icons/CalendarTwoTone';
import { makeSelectSamplesList, makeSelectSamplesOverview } from "../../stores/blood-samples/BloodSamplesDataSelector";
import ReqGetAllSamples from "../../stores/blood-samples/request/ReqGetAllSamples";
import { useDispatch, useSelector } from "react-redux";
import BloodSamplesAction from "../../stores/blood-samples/BloodSamplesAction";
import ReqGetSamplesOverview from "../../stores/blood-samples/request/ReqGetSamplesOverview";
import FooterFixedBottom from "../components/navigation/FooterFixedBottom";
import { AppRoutes } from "../../assets/constants/routes";
import { Link } from 'react-router-dom';
import { selectFinished } from "../../stores/special/finished/FinishedSelector";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { getCookie, setCookie } from "../../assets/function/CustomFunction";

const { RangePicker } = DatePicker;
const { Option } = Select;
const HomePage = (props) => {
  const [searchBy, setSearchBy] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const [gender, setGender] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [collectionDate, setCollectionDate] = useState(null)
  const samplesList = useSelector(state => makeSelectSamplesList(state));
  const samplesOverview = useSelector(state => makeSelectSamplesOverview(state));
  const dispatch = useDispatch();
  const isFinishedCreate = useSelector((state) => selectFinished(state, BloodSamplesAction.REQUEST_CREATE_BLOOD_SAMPLES));
  const isFinishedUpdate = useSelector((state) => selectFinished(state, BloodSamplesAction.REQUEST_UPDATE_BLOOD_SAMPLES));
  const isFinishedDelete = useSelector((state) => selectFinished(state, BloodSamplesAction.REQUEST_DELETE_BLOOD_SAMPLES));
  useEffect(() => {
    dispatch(BloodSamplesAction._requestGetAllSamples(new ReqGetAllSamples({})));
    dispatch(BloodSamplesAction._requestGetSamplesDataOverview(new ReqGetSamplesOverview()));
  }, [isFinishedCreate, isFinishedUpdate, isFinishedDelete]);

  const [pageSize, setPageSize] = useState((getCookie('pageSize')==='undefined' || !getCookie('pageSize'))? 50 : Number(getCookie('pageSize')));
  const [current, setCurrent] = useState((getCookie('current')==='undefined' || !getCookie('current'))? 1 : Number(getCookie('current')));
  const [sort, setSort] = useState((getCookie('sort')==='undefined' || !getCookie('sort'))? 'sampleId' : getCookie('sort'));
  const [sortOrder, setSortOrder] = useState((getCookie('sortOrder')==='undefined' || !getCookie('sortOrder'))? 'ascend' : getCookie('sortOrder'));
  const fetchData = () => {
    let filterOptions = {};
    filterOptions['pageId'] = current;
    filterOptions['limit'] = pageSize;
    filterOptions['sort'] = sort;
    filterOptions['sortOrder'] = sortOrder;
    filterOptions['gender'] = gender;
    if (searchBy && searchValue) {
      filterOptions[searchBy] = searchValue;
    }
    if (fromDate && toDate) {
      filterOptions['fromDate'] = fromDate;
      filterOptions['toDate'] = toDate;
    }
    if(collectionDate){
      filterOptions['collectionDate'] = collectionDate;
    }
    if ((fromDate && toDate) || (searchValue && searchBy) || typeof gender === 'string') {
      dispatch(BloodSamplesAction._requestGetAllSamples(new ReqGetAllSamples(filterOptions)));
    }
  };
  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, gender, sort, sortOrder, pageSize, current, collectionDate])
  const dateFormat = 'DD/MM/YYYY';
  const updateDateRange = (date, dateStrings) => {
    setFromDate(dateStrings[0]);
    setToDate(dateStrings[1]);
  }
  const resetAllFilter = () => {
    setSearchValue('');
    setFromDate(null);
    setToDate(null);
    setCollectionDate(null)
    setGender('');
    fetchData();
    // dispatch(BloodSamplesAction._requestGetAllSamples(new ReqGetAllSamples({})));


  }
  const searchByOnchangeHandler = (value) => {
    setSearchBy(value);
    setSearchValue('');
    document.getElementById('searchInput').focus()
  }
  const OnChangeCollectionDate = (value, dateString)=>{
    setCollectionDate(dateString)
  }
  const handleTableChange = (pagination, filters, sorter) => {
    if(Object.keys(pagination).length){
      setCookie('current', pagination.current);
      setCookie('pageSize', pagination.pageSize);
      setCurrent(pagination.current);
      setPageSize(pagination.pageSize);
    }
    if(Object.keys(sorter).length){
      setCookie('sort', sorter.field);
      setCookie('sortOrder', sorter.order);
      setSort(sorter.field);
      setSortOrder(sorter.order)
    }
    console.log(pagination, sorter);
  }
  const selectBefore = (
    <Select
      defaultValue="name"
      onChange={(value) => searchByOnchangeHandler(value)}
      style={{ width: "130px", backgroundColor: '#F1F1F1', height: '40px', border: '1px solid #d9d9d9', borderRight: 'none' }}
    >
      <Option value="name">Participant</Option>
      <Option value="aboveGlucoseResultMmol">Glucose</Option>
      <Option value="aboveTgResultMgdl">TG</Option>
      <Option value="aboveCholesterolResultMgdl">Cholesterol</Option>
      <Option value="lessHdlResultMgdl">HDL</Option>
      <Option value="aboveLdlResultMgdl">LDL</Option>
      <Option value="sampleId">Sample Id</Option>
      <Option value="id">Cross Id</Option>
    </Select>
  );
  return (
    <>
      <div className="page-basic-container">
        <TopNavBar />
        <div className="card-decoration">
          <CardBasic dataTitle="Total Sample Entry" dataCount={samplesOverview && samplesOverview.totalEntry ? samplesOverview.totalEntry : 0} />
          <CardBasic dataTitle="Todays Entry" dataCount={samplesOverview && samplesOverview.todayEntry ? samplesOverview.todayEntry : 0} />
          <CardBasic dataTitle="30 Days Entry" dataCount={samplesOverview && samplesOverview.lastThirtyDaysEntry ? samplesOverview.lastThirtyDaysEntry : 0} />
        </div>
        <Row className="filter-block">
          <Col sm={{ span: 24 }} lg={{ span: 7 }}>

            <div className="search-container">
              <div>
                <p onClick={resetAllFilter}>Clear Filters</p>
              </div>
              <Input.Search
                placeholder={searchBy === 'name' ? 'Enter Name' : searchBy === 'lessHdlResultMgdl' ? '<=' : searchBy === 'id' ? 'Enter Cross Id' : searchBy === 'sampleId' ? 'Enter Sample Id': '>='}
                onSearch={() => fetchData()}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                addonBefore={selectBefore}
                id='searchInput'
              />
            </div>
          </Col>
          <Col sm={{ span: 24 }} lg={{ span: 5 }} style={!isMobile && { paddingLeft: '10px' }}>
            <RangePicker
              allowClear={false}
              value={fromDate && toDate ? [moment(fromDate, dateFormat), moment(toDate, dateFormat)] : []}
              style={{ height: "40px", width: "100%" }}
              format={dateFormat}
              suffixIcon={<CalendarTwoTone />}
              onChange={(date, dateStrings) => updateDateRange(date, dateStrings)}
            />
         
          </Col>
          <Col sm={{ span: 24 }} lg={{ span: 3 }}>
          <DatePicker
              placeholder={'Collection Date'}
              style={!isMobile ?{marginLeft: '10px',height: "40px", width: "100%" }:{height: "40px", width: "100%" }}
              value={collectionDate&&moment(collectionDate, dateFormat)}
              format={dateFormat}
              suffixIcon={<CalendarTwoTone />}
              onChange={OnChangeCollectionDate}
            />
          </Col>
          <Col sm={{ span: 24 }} lg={{ span: 4 }} style={!isMobile && { paddingLeft: '10px' }}>
            <Select onChange={(value) => setGender(value)} value={gender} size="large" style={{ width: "150px",  marginLeft: '10px' }} placeholder="Please select gender">
              <Option value="">All Gender</Option>
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
              <Option value="transgender">Transgender</Option>
              <Option value="others">Others</Option>
            </Select>
          </Col>
          <Col sm={{ span: 24 }} lg={{ span: 5 }} style={{ textAlign: 'end' }}>
            <Link to={AppRoutes.CREATE_BLOOD_SAMPLES}>
              <span className="custom-green-button">
                <Button size="small" type="primary" >
                  <span style={{ display: "flex", alignItems: "center" }}><PlusOutlined style={{ marginRight: '5px', fontSize: '16px' }} />New Entry</span>
                </Button>
              </span>
            </Link>
          </Col>
        </Row>
        <BloodSamplesTable pageSize={pageSize} current={current} sort={sort} sortOrder={sortOrder} handleTableChange={handleTableChange} className="sample-table" samplesList={samplesList} />
      </div>
      <FooterFixedBottom />
    </>
  );
};

export default HomePage;
