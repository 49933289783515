import ActionUtility from '../../utils/ActionUtility';
import DbBackupEffect from './DbBackupEffect';
import UserEffect from './DbBackupEffect';

export default class DbBackupAction {

    static REQUEST_GET_DRIVE_LINK = 'REQUEST_GET_DRIVE_LINK';
    static REQUEST_GET_DRIVE_LINK_FINISHED = 'REQUEST_GET_DRIVE_LINK_FINISHED';

    static _requestGetDriveLink(request) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(dispatch, DbBackupAction.REQUEST_GET_DRIVE_LINK, DbBackupEffect._requestGetDriveLink, request )
        }
    }

}