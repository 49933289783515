

export class ApiEndpoint {
    // static BASE_ENDPOINT = "http://192.168.0.99:4000/api/v1";
    static BASE_ENDPOINT = "https://nibresdata.com:4000/api/v1";
    // static BASE_ENDPOINT = "http://192.168.100.143:4000/api/v1";                                                       

    static user = {
        getUserDetails: `${ApiEndpoint.BASE_ENDPOINT}/user/getUserDetails`,
    }
    static bloodSamples = {
        getAll: `${ApiEndpoint.BASE_ENDPOINT}/samples/getBloodSamples`,
        getById: `${ApiEndpoint.BASE_ENDPOINT}/samples/getBloodSamplesById`,
        getDataOverview: `${ApiEndpoint.BASE_ENDPOINT}/samples/getDataOverview`,
        createNew: `${ApiEndpoint.BASE_ENDPOINT}/samples/createNewBloodSamples`,
        update: `${ApiEndpoint.BASE_ENDPOINT}/samples/updateBloodSamples`,
        delete: `${ApiEndpoint.BASE_ENDPOINT}/samples/deleteBloodSamples`,
        downloadExcel: `${ApiEndpoint.BASE_ENDPOINT}/samples/downloadExcel`,
    }
    static db_backup = {
        getDriveLink: `${ApiEndpoint.BASE_ENDPOINT}/backup/getDriveLink`,
    }

}