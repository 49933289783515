import moment from 'moment';
import BaseRequestBody from '../../../models/BaseRequestBody';

export default class ReqGetAllSamples extends  BaseRequestBody {
    name = ''
    sampleId = 0
    id = 0
    fromDate = ''
    toDate = ''
    collectionDate= ''
    gender = ''
    aboveGlucoseResultMmol = 0
    aboveTgResultMgdl = 0
    aboveCholesterolResultMgdl = 0
    lessHdlResultMgdl = 0
    aboveLdlResultMgdl = 0
    pageId = 1
    limit = 10
    sort = ''
    sortOrder = ''

    constructor(data){
        super();
        this.update(data);
        if(data.sampleId)this.sampleId=Number(data.sampleId);
        if(data.id)this.id=Number(data.id);
        if(data.pageId)this.pageId=Number(data.pageId);
        if(data.limit)this.limit=Number(data.limit);
        if(data.aboveGlucoseResultMmol)this.aboveGlucoseResultMmol=Number(data.aboveGlucoseResultMmol)
        if(data.aboveTgResultMgdl)this.aboveTgResultMgdl=Number(data.aboveTgResultMgdl)
        if(data.aboveCholesterolResultMgdl)this.aboveCholesterolResultMgdl=Number(data.aboveCholesterolResultMgdl)
        if(data.lessHdlResultMgdl)this.lessHdlResultMgdl=Number(data.lessHdlResultMgdl)
        if(data.aboveLdlResultMgdl)this.aboveLdlResultMgdl=Number(data.aboveLdlResultMgdl)
        if(data.fromDate)this.fromDate = moment(data.fromDate, 'DD/MM/YYYY').format('YYYY-MM-DD')+ ' 00:00:00'
        if(data.toDate)this.toDate = moment(data.toDate, 'DD/MM/YYYY').format('YYYY-MM-DD')+' 23:59:59'
        if(data.collectionDate)this.collectionDate = moment(data.collectionDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        if(data.sortOrder==='ascend')this.sortOrder = 'ASC';
        if(data.sortOrder==='descend')this.sortOrder = 'DESC';
        
    }
}