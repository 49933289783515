import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  Checkbox,
  Row,
  Col,
  Spin,
  Empty,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppRoutes } from "../../assets/constants/routes";
import BloodSamplesAction from "../../stores/blood-samples/BloodSamplesAction";
import { selectRequesting } from "../../stores/special/requesting/RequestingSelector";
import { Link } from "react-router-dom";
import "../components/components.css";
import FooterFixedBottom from "../components/navigation/FooterFixedBottom";
import { useParams } from "react-router-dom";
import moment from "moment";
import ReqGetSampleById from "../../stores/blood-samples/request/ReqGetSampleById";
import { makeSelectSampleById } from "../../stores/blood-samples/BloodSamplesDataSelector";
import ReqUpdateSamples from "../../stores/blood-samples/request/ReqUpdateSamples";
import { isMobile } from "react-device-detect";
import {calculateBmi} from '../../assets/function/CustomFunction';
import "../forms/form-style.css";
import RadioGroupConponent from "../components/misc/RadioGroupComponent";

const { Option } = Select;
const UpdateBloodSamples = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(
      BloodSamplesAction._requestGetAllSampleById(
        new ReqGetSampleById({ id: id })
      )
    );
  }, [id]);
  const formData = useSelector((state) => makeSelectSampleById(state));
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const isRequesting = useSelector((state) =>
    selectRequesting(state, [BloodSamplesAction.REQUEST_UPDATE_BLOOD_SAMPLES])
  );
  const isRequestingData = useSelector((state) =>
    selectRequesting(state, [BloodSamplesAction.REQUEST_GET_BLOOD_SAMPLE_BY_ID])
  );
  if (isRequesting) {
    document.getElementById("cover-spin").style.display = "block";
  } else {
    document.getElementById("cover-spin").style.display = "none";
  }
  const [form] = Form.useForm();
  const [displayComponent, setDisplayComponent] = useState(
    {
      isDrugAddicted: false,
      isPerformPhysicalActivities: false,
      isAffectedDisease: false,
      isRelativeAffectedDisease: false,

    })
  const [disableType, setDisableType] = useState(
    {
      smoking: true,
      jorda: true,
      alcohol: true,
      otherDrug: true,
      walking: true,
      householdTasks: true,
      exercise: true,
      otherPhysicalActivity:true,
      bloodPressure: true,
      cardiovascularDisease: true,
      atherosclerosis: true,
      brainStroke: true,
      diabetes: true,
      otherDisease: true
    })
  useEffect(() => {
    if (form && formData && formData.id) {
      setDisplayComponent({
        isDrugAddicted: formData.isDrugAddicted?formData.isDrugAddicted:false,
        isPerformPhysicalActivities: formData.isPerformPhysicalActivities?formData.isPerformPhysicalActivities:false,
        isAffectedDisease: formData.isAffectedDisease?formData.isAffectedDisease:false,
        isRelativeAffectedDisease: formData.isRelativeAffectedDisease?formData.isRelativeAffectedDisease:false,
      })
      setDisableType({
        smoking: formData.drugAddictionData[0]?.type==='smoking'?false:true,
        jorda: formData.drugAddictionData[1]?.type==='jorda'?false:true,
        alcohol: formData.drugAddictionData[2]?.type==='alcohol'?false:true,
        otherDrug: formData.drugAddictionData[3]?.type==='otherDrug'?false:true,
        walking: formData.physicalActivitiesData[0]?.type==='walking'?false:true,
        householdTasks: formData.physicalActivitiesData[1]?.type==='householdTasks'?false:true,
        exercise: formData.physicalActivitiesData[2]?.type==='exercise'?false:true,
        otherPhysicalActivity:formData.physicalActivitiesData[3]?.type==='otherPhysicalActivity'?false:true,
        bloodPressure: formData.affectedDiseasesData[0]?.type==='bloodPressure'?false:true,
        cardiovascularDisease: formData.affectedDiseasesData[1]?.type==='cardiovascularDisease'?false:true,
        atherosclerosis: formData.affectedDiseasesData[2]?.type==='atherosclerosis'?false:true,
        brainStroke: formData.affectedDiseasesData[3]?.type==='brainStroke'?false:true,
        diabetes: formData.affectedDiseasesData[4]?.type==='diabetes'?false:true,
        otherDisease: formData.affectedDiseasesData[5]?.type==='otherDisease'?false:true
      })
      
      //Set Initial values
      form.setFieldsValue({
        ...formData,
        sampleId: formData.sampleId.toString(),
        height: formData.height.toString(),
        weight: formData.weight.toString(),
        bmi: formData.bmi.toString(),
        glucoseResultMgdl: formData.glucoseResultMgdl.toString(),
        tgResultMgdl: formData.tgResultMgdl.toString(),
        cholesterolResultMgdl: formData.cholesterolResultMgdl.toString(),
        hdlResultMgdl: formData.hdlResultMgdl.toString(),
        systolicBloodPressure: formData.systolicBloodPressure.toString(),
        diastolicBloodPressure: formData.diastolicBloodPressure.toString(),
        testDate: formData.testDate ? moment(formData.testDate) : "",
        birthDate: formData.birthDate ? moment(formData.birthDate) : "",
        collectionDate: formData.collectionDate
          ? moment(formData.collectionDate)
          : "",
      });
    }
  }, [form, formData]);
  const onFinish = (values) => {
    dispatch(
      BloodSamplesAction._requestUpdateBloodSamples(
        new ReqUpdateSamples({ ...values, id: formData.id })
      )
    );

  };
  const handleBmi = () =>{
    let values = form.getFieldsValue(['weight','height']);
    let bmi = calculateBmi(values.weight, values.height);
    form.setFieldsValue({
      bmi:bmi
    })
  }
  const handleDisplayResetComponent = (e, fieldName, stateName) => {
    if (e.target.value === false) {
      let data = form.getFieldValue(fieldName);
      setDisplayComponent({ ...displayComponent, [stateName]: false });
      Array.from(document.getElementsByClassName(fieldName)).forEach(el => el.checked = false);
      if (stateName === 'isDrugAddicted') {
        data.map((item, index) => {
          data[index].type = "";
          data[index].forHowLong = "";
          data[index].dailyAmount = "";
        })
        setDisableType({
          ...disableType,
          smoking: true,
          jorda: true,
          alcohol: true,
          otherDrug:true
        });
      }
      if (stateName === 'isPerformPhysicalActivities') {
        data.map((item, index) => {
          data[index].type = "";
          data[index].forHowLong = "";
          data[index].dailyAmount = "";
        })
        setDisableType({
          ...disableType,
          walking: true,
          householdTasks: true,
          exercise: true,
          otherPhysicalActivity: true
        });
      }
      if (stateName === 'isAffectedDisease') {
        data.map((item, index) => {
          data[index].type = "";
          data[index].forHowLong = "";
          data[index].medicineConsumption = "";
        })
        setDisableType({
          ...disableType,
          bloodPressure: true,
          cardiovascularDisease: true,
          atherosclerosis: true,
          brainStroke: true,
          diabetes: true,
          otherDisease: true
        });
      }
      if (stateName === 'isRelativeAffectedDisease') {
        data.map((item, index) => {
          data[index].member = "";
          data[index].diseases = [];
          data[index].otherSpecified = "";
          data[index].numberOfMember = "";
        })
      }
    } else {
      setDisplayComponent({ ...displayComponent, [stateName]: true })
    }
  }
  const handleTypeSelection = (e, fieldName, index, value) => {
    // console.log("if ", fieldName, index, value);
    let data = form.getFieldValue(fieldName);
    if (e.target.checked) {
      data[index].type = value;
      setDisableType({
        ...disableType,
        [value]: false
      });
    } else {
      if (fieldName === 'affectedDiseasesData') {
        const newData = [];
        data.map((item, i) => {
          if (index === i) {
            newData.push({
              type: '',
              forHowLong: '',
              medicineConsumption: ''
            })
          } else {
            newData.push(data[i])
          }
        })
        form.setFieldsValue({
          [fieldName]: newData
        })
      } else {
        const newData = [];
        data.map((item, i) => {
          if (index === i) {
            newData.push({
              type: '',
              forHowLong: '',
              dailyAmount: ''
            })
          } else {
            newData.push(data[i])
          }
        })
        form.setFieldsValue({
          [fieldName]: newData
        })
      }
      setDisableType({
        ...disableType,
        [value]: true
      });
    }
  }
  return (
    <>
      {formData && formData.id == id ? (
        <div className="form-basic-container">
          <div className="form-title">
            <div>
              <Link to={AppRoutes.DEFAULT}>
                <span className="no-styled-button">
                  <Button type={"primary"}>
                    <ArrowLeftOutlined />
                    {isMobile
                      ? "Data Entry Form"
                      : "Data Entry Form - HMGCR Gene SNP Analysis"}
                  </Button>
                </span>
              </Link>
            </div>
            <div>
              <span className="custom-green-button">
                <Button type={"primary"} onClick={() => form.submit()}>
                  Save & Exit
                </Button>
              </span>
            </div>
          </div>
          <div className="form-container">
            <Form
              form={form}
              {...layout}
              name={"save-diagnostic"}
              onFinish={onFinish}
              validateTrigger="onChange"
            >
              <div className="form-block-colored-container">
                <h2 className="form-block-header">
                  Data Collection Information
                </h2>
                <Row className="form-row-block">
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        label={"Collection Date"}
                        name={"collectionDate"}
                      >
                        <DatePicker format='DD/MM/YYYY' />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        label={"Collected By"}
                        name={"collectedBy"}
                        rules={[
                          {
                            max: 50,
                            message: "Name can't exceed 50 characters",
                          },
                          {
                            pattern: new RegExp("^[ a-zA-z._]*$"),
                            message: "characters and (. and _) are allowed only",
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        label={"Examined By"}
                        name={"examinedBy"}
                        rules={[
                          {
                            max: 50,
                            message: "Name can't exceed 50 characters",
                          },
                          {
                            pattern: new RegExp("^[ a-zA-z._]*$"),
                            message: "characters and (. and _) are allowed only",
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                </Row>
              </div>
              <div className="form-block-colored-container">
                <h2 className="form-block-header">Participant Information</h2>
                <Row className="form-row-block">
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={"Name"}
                      name={"name"}
                      rules={[
                        {
                          max: 50,
                          message: "Name can't exceed 50 characters",
                        },
                        {
                          required: true,
                          message: "Name is required",
                        },
                        {
                          pattern: new RegExp("^[ a-zA-z._]*$"),
                          message: "characters and (. and _) are allowed only",
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 24 }} lg={{ span: 8 }} >
                  <Form.Item
                    label={"Gender"}
                    name={"gender"}
                    rules={[
                      {
                        required: true,
                        message: 'Gender is required'
                      }
                    ]}
                  >
                    <Select placeholder="Please select gender">
                      <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                      <Option value="transgender">Transgender</Option>
                      <Option value="others">Others</Option>
                    </Select>
                  </Form.Item>
                </Col>
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={"Sample Id"}
                      name={"sampleId"}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9]*$"),
                          message: "Only numbers are allowed",
                        },
                        { min: 1, max: 10, message: "Can't exceed length 10 digit" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  </Row>
                  <Row className="form-row-block">
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={"Cross ID"}
                    >
                    <p style={{paddingLeft:'20px'}}>{formData.id}</p>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <h3 style={{ textAlign: "center", marginTop: "30px" }}>
                Result Data
              </h3>
              <div className="form-block-container">
                <h2 className="form-block-header">Test Date</h2>
                <Row className="form-row-block">
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item label={"Enter or select date"} name={"testDate"}>
                      <DatePicker format='DD/MM/YYYY' />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="form-block-container">
                <h2 className="form-block-header">1. Glucose</h2>
                <Row className="form-row-block">
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={"Result (mg/dl)"}
                      name={"glucoseResultMgdl"}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9.]*$"),
                          message: "Only postive numbers are allowed",
                        },
                        { max: 10, message: "Can't exceed length 10" },
                      ]}
                    >
                      <Input placeholder={"Enter value"} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="form-block-container">
                <h2 className="form-block-header">2. Lipid Profile</h2>
                <Row className="form-row-block">
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={"TG Result (mg/dl)"}
                      name={"tgResultMgdl"}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9.]*$"),
                          message: "Only postive numbers are allowed",
                        },
                        { max: 10, message: "Can't exceed length 10" },
                      ]}
                    >
                      <Input placeholder={"Enter value"} />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={"Cholesterol Result (mg/dl)"}
                      name={"cholesterolResultMgdl"}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9.]*$"),
                          message: "Only postive numbers are allowed",
                        },
                        { max: 10, message: "Can't exceed length 10" },
                      ]}
                    >
                      <Input placeholder={"Enter value"} />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={"HDL Result (mg/dl)"}
                      name={"hdlResultMgdl"}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9.]*$"),
                          message: "Only postive numbers are allowed",
                        },
                        { max: 10, message: "Can't exceed length 10" },
                      ]}
                    >
                      <Input placeholder={"Enter value"} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="form-block-colored-container">
                <h2 className="form-block-header">
                  Participant Personal Information
                </h2>
                <Row className="form-row-block">
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item label={"Date of Birth"} name={"birthDate"}>
                      <DatePicker format='DD/MM/YYYY' />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={"Place of Birth"}
                      name={"placeOfBirth"}
                      rules={[
                        {
                          max: 120,
                          message: "Place length can't exceed 120 characters",
                        },
                        {
                          pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                          message: "numbers, characters and (, . _ -) are allowed only",
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={"Address"}
                      name={"address"}
                      rules={[
                        {
                          max: 120,
                          message: "Address can't exceed 120 characters",
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="form-row-block">
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={"Mobile Number"}
                      name={"mobileNumber"}
                      rules={[
                        {
                          max: 14,
                          message:
                            "Mobile Number length can't exceed 14 numbers",
                        },
                        {
                          pattern: new RegExp("^[0-9+-]*$"),
                          message: "numbers, + and - are allowed only",
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={"Profession"}
                      name={"profession"}
                      rules={[
                        {
                          max: 40,
                          message: "Profession can't exceed 40 characters",
                        },
                        {
                          pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                          message: "numbers, characters and (, . _ -) are allowed only",
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <p style={{ color: "gray", fontSize: "14px" }}>
                  Personal Information
                </p>
                <Row className="form-row-block">
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={"Systolic Blood Pressure"}
                      name={"systolicBloodPressure"}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9.]*$"),
                          message: "Only postive numbers are allowed",
                        },
                        { max: 10, message: "Can't exceed length 10" },
                      ]}
                    >
                      <Input placeholder={"Enter value"} />
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={"Diastolic Blood Pressure"}
                      name={"diastolicBloodPressure"}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9.]*$"),
                          message: "Only postive numbers are allowed",
                        },
                        { max: 10, message: "Can't exceed length 10" },
                      ]}
                    >
                      <Input placeholder={"Enter value"} />
                    </Form.Item>
                  </Col>
                  
                </Row>
                <Row className="form-row-block">
                <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={"Height in cm (i.e 175)"}
                      name={"height"}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9.]*$"),
                          message: "Only postive numbers are allowed",
                        },
                        { max: 10, message: "Can't exceed length 10" },
                      ]}
                    >
                      <Input placeholder={"Enter value"} onChange={handleBmi}/>
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={"Weight in KG"}
                      name={"weight"}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9.]*$"),
                          message: "Only postive numbers are allowed",
                        },
                        { max: 10, message: "Can't exceed length 10" },
                      ]}
                    >
                      <Input placeholder={"Enter value"} onChange={handleBmi}/>
                    </Form.Item>
                  </Col>
                  <Col md={{ span: 24 }} lg={{ span: 8 }}>
                    <Form.Item
                      label={
                        <span>
                          BMI (KG/m<sup>2</sup>)
                        </span>
                      }
                      name={"bmi"}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9.]*$"),
                          message: "Only postive numbers are allowed",
                        },
                        { max: 10, message: "Can't exceed length 10" },
                      ]}
                    >
                      <Input placeholder={"Auto Calculated"} disabled/>
                    </Form.Item>
                  </Col>
                </Row>
                <p style={{ color: "gray", fontSize: "14px" }}>Habits</p>
                 {/* Drug Addiction Data Part Starts */}
                 <Form.Item
                name="isDrugAddicted"
                label="1. Addicted to smoking/jorda/alcohol? "
                labelCol={{ span: 14.5 }}
                wrapperCol={{ span: 7.5 }}
              >
                <Radio.Group value={displayComponent.isDrugAddicted} style={{ marginLeft: "10px" }} onChange={(e) => handleDisplayResetComponent(e, 'drugAddictionData', 'isDrugAddicted')}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <div style={displayComponent.isDrugAddicted ? { overflow: 'auto hidden', display: 'block' } : { overflow: 'auto hidden', display: 'none' }}>
              <table className="responsive-table">
                 {
                  !isMobile&& 
                  <colgroup>
                   <col style={{ width: '20%' }} />
                   <col style={{ width: '20%' }} />
                   <col style={{ width: '20%' }} />
                   <col style={{ width: '40%' }} />
                 </colgroup>
                 }
                <thead>
                  <tr style={{ textAlign: 'center' }}>
                    <td>Type of addiction</td>
                    <td>For how long</td>
                    <td>Daily amount</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Item>
                        <div className="vertical-centered">
                          <input className="drugAddictionData" defaultChecked={formData.drugAddictionData[0]?.type==='smoking'?true:false} id="smoking" type='checkbox' onChange={(e) => handleTypeSelection(e, 'drugAddictionData', 0, 'smoking')} />
                          <label htmlFor="smoking"> Smoking</label>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name={['drugAddictionData', 0, 'type']}
                        initialValue={""}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['drugAddictionData', 0, 'forHowLong']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.smoking} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['drugAddictionData', 0, 'dailyAmount']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.smoking} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item>
                        <div className="vertical-centered">
                          <input className="drugAddictionData" id="jorda" type='checkbox' defaultChecked={formData.drugAddictionData[1]?.type==='jorda'?true:false} onChange={(e) => handleTypeSelection(e, 'drugAddictionData', 1, 'jorda')} />
                          <label htmlFor="jorda"> Jorda</label>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name={['drugAddictionData', 1, 'type']}
                        initialValue={""}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['drugAddictionData', 1, 'forHowLong']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.jorda} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['drugAddictionData', 1, 'dailyAmount']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.jorda} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item>
                        <div className="vertical-centered">
                          <input className="drugAddictionData" id="alcohol" type='checkbox' defaultChecked={formData.drugAddictionData[2]?.type==='alcohol'?true:false} onChange={(e) => handleTypeSelection(e, 'drugAddictionData', 2, 'alcohol')} />
                          <label htmlFor="alcohol"> Alcohol</label>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name={['drugAddictionData', 2, 'type']}
                        initialValue={""}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['drugAddictionData', 2, 'forHowLong']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.alcohol} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['drugAddictionData', 2, 'dailyAmount']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.alcohol} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item>
                        <div className="vertical-centered">
                          <input className="drugAddictionData" id="otherDrug" type='checkbox' defaultChecked={formData.drugAddictionData[3]?.type==='otherDrug'?true:false} onChange={(e) => handleTypeSelection(e, 'drugAddictionData', 3, 'otherDrug')} />
                          <label htmlFor="otherDrug"> Other, Specify</label>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name={['drugAddictionData', 3, 'type']}
                        initialValue={""}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['drugAddictionData', 3, 'forHowLong']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.otherDrug} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['drugAddictionData', 3, 'dailyAmount']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.otherDrug} />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
              {
                displayComponent.isDrugAddicted&&<hr className="horizontal-seperator"/>
              }
              {/* Drug Addiction Data Part Ends */}
              {/* Physical Activities Data Part Starts */}
              <Form.Item
                name="isPerformPhysicalActivities"
                label="2. To stay fit, does he/she perform physical activities (walking/household tasks/exercise)? "
                labelCol={{ span: 14.5 }}
                wrapperCol={{ span: 7.5 }}
              >
                <Radio.Group value={displayComponent.isPerformPhysicalActivities} style={{ marginLeft: "10px" }} onChange={(e) => handleDisplayResetComponent(e, 'physicalActivitiesData', 'isPerformPhysicalActivities')}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <div style={displayComponent.isPerformPhysicalActivities ? { overflow: 'auto hidden', display: 'block' } : { overflow: 'auto hidden', display: 'none' }}>
              <table className="responsive-table">
                {
                  !isMobile&& 
                  <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '40%' }} />
                  </colgroup>
                }
              <thead>
                  <tr style={{ textAlign: 'center' }}>
                    <td>Type of physical activities</td>
                    <td>For how long</td>
                    <td>Daily amount</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Item>
                        <div className="vertical-centered">
                          <input className="physicalActivitiesData" id="walking" type='checkbox' defaultChecked={formData.physicalActivitiesData[0]?.type==='walking'?true:false} onChange={(e) => handleTypeSelection(e, 'physicalActivitiesData', 0, 'walking')} />
                          <label htmlFor="walking"> Walking</label>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name={['physicalActivitiesData', 0, 'type']}
                        initialValue={""}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['physicalActivitiesData', 0, 'forHowLong']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.walking} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['physicalActivitiesData', 0, 'dailyAmount']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.walking} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item>
                        <div className="vertical-centered">
                          <input className="physicalActivitiesData" id="householdTasks" type='checkbox' defaultChecked={formData.physicalActivitiesData[1]?.type==='householdTasks'?true:false} onChange={(e) => handleTypeSelection(e, 'physicalActivitiesData', 1, 'householdTasks')} />
                          <label htmlFor="householdTasks"> Household Tasks</label>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name={['physicalActivitiesData', 1, 'type']}
                        initialValue={""}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['physicalActivitiesData', 1, 'forHowLong']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.householdTasks} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['physicalActivitiesData', 1, 'dailyAmount']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.householdTasks} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item>
                        <div className="vertical-centered">
                          <input className="physicalActivitiesData" id="exercise" type='checkbox' defaultChecked={formData.physicalActivitiesData[2]?.type==='exercise'?true:false} onChange={(e) => handleTypeSelection(e, 'physicalActivitiesData', 2, 'exercise')} />
                          <label htmlFor="exercise"> Exercise</label>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name={['physicalActivitiesData', 2, 'type']}
                        initialValue={""}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['physicalActivitiesData', 2, 'forHowLong']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.exercise} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['physicalActivitiesData', 2, 'dailyAmount']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.exercise} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item>
                        <div className="vertical-centered">
                          <input className="physicalActivitiesData" id="otherPhysicalActivity" type='checkbox' defaultChecked={formData.physicalActivitiesData[3]?.type==='otherPhysicalActivity'?true:false} onChange={(e) => handleTypeSelection(e, 'physicalActivitiesData', 3, 'otherPhysicalActivity')} />
                          <label htmlFor="otherPhysicalActivity"> Other, specify</label>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name={['physicalActivitiesData', 3, 'type']}
                        initialValue={""}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['physicalActivitiesData', 3, 'forHowLong']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.otherPhysicalActivity} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['physicalActivitiesData', 3, 'dailyAmount']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.otherPhysicalActivity} />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
              {
                displayComponent.isPerformPhysicalActivities&&<hr className="horizontal-seperator"/>
              }
              {/* Physical Activities Data Part Ends */}
              {/* Affected Diseases Data Part Starts */}
              <Form.Item
                name="isAffectedDisease"
                label="3. Does he/she have blood pressure/cardiovascular disease/atherosclerosis/brain stroke? "
                labelCol={{ span: 14.5 }}
                wrapperCol={{ span: 7.5 }}
              >
                <Radio.Group value={displayComponent.isAffectedDisease} style={{ marginLeft: "10px" }} onChange={(e) => handleDisplayResetComponent(e, 'affectedDiseasesData', 'isAffectedDisease')}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <div style={displayComponent.isAffectedDisease ? { overflow: 'auto hidden', display: 'block' } : { overflow: 'auto hidden', display: 'none' }}>
              <table className="responsive-table">
                {
                  !isMobile&& 
                  <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '40%' }} />
                  </colgroup>
                }
                <thead>
                  <tr style={{ textAlign: 'center' }}>
                    <td>Type of disease</td>
                    <td>For how long he/she<br/>has been suffering</td>
                    <td>Name(s) of medicine he/she<br/>has been taking</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Item>
                        <div className="vertical-centered">
                          <input className="affectedDiseasesData" id="bloodPressure" type='checkbox' defaultChecked={formData.affectedDiseasesData[0]?.type==='bloodPressure'?true:false} onChange={(e) => handleTypeSelection(e, 'affectedDiseasesData', 0, 'bloodPressure')} />
                          <label htmlFor="bloodPressure"> Blood Pressure</label>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name={['affectedDiseasesData', 0, 'type']}
                        initialValue={""}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['affectedDiseasesData', 0, 'forHowLong']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.bloodPressure} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['affectedDiseasesData', 0, 'medicineConsumption']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.bloodPressure} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item>
                        <div className="vertical-centered">
                          <input className="affectedDiseasesData" id="cardiovascularDisease" type='checkbox' defaultChecked={formData.affectedDiseasesData[1]?.type==='cardiovascularDisease'?true:false} onChange={(e) => handleTypeSelection(e, 'affectedDiseasesData', 1, 'cardiovascularDisease')} />
                          <label htmlFor="cardiovascularDisease"> Cardiovascular Disease</label>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name={['affectedDiseasesData', 1, 'type']}
                        initialValue={""}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['affectedDiseasesData', 1, 'forHowLong']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.cardiovascularDisease} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['affectedDiseasesData', 1, 'medicineConsumption']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.cardiovascularDisease} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item>
                        <div className="vertical-centered">
                          <input className="affectedDiseasesData" id="atherosclerosis" type='checkbox' defaultChecked={formData.affectedDiseasesData[2]?.type==='atherosclerosis'?true:false} onChange={(e) => handleTypeSelection(e, 'affectedDiseasesData', 2, 'atherosclerosis')} />
                          <label htmlFor="atherosclerosis"> Atherosclerosis</label>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name={['affectedDiseasesData', 2, 'type']}
                        initialValue={""}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['affectedDiseasesData', 2, 'forHowLong']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.atherosclerosis} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['affectedDiseasesData', 2, 'medicineConsumption']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.atherosclerosis} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item>
                        <div className="vertical-centered">
                          <input className="affectedDiseasesData" id="brainStroke" type='checkbox' defaultChecked={formData.affectedDiseasesData[3]?.type==='brainStroke'?true:false} onChange={(e) => handleTypeSelection(e, 'affectedDiseasesData', 3, 'brainStroke')} />
                          <label htmlFor="brainStroke"> Brain Stroke</label>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name={['affectedDiseasesData', 3, 'type']}
                        initialValue={""}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['affectedDiseasesData', 3, 'forHowLong']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.brainStroke} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['affectedDiseasesData', 3, 'medicineConsumption']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.brainStroke} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item>
                        <div className="vertical-centered">
                          <input className="affectedDiseasesData" id="diabetes" type='checkbox' defaultChecked={formData.affectedDiseasesData[4]?.type==='diabetes'?true:false} onChange={(e) => handleTypeSelection(e, 'affectedDiseasesData', 4, 'diabetes')} />
                          <label htmlFor="diabetes"> Diabetes</label>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name={['affectedDiseasesData', 4, 'type']}
                        initialValue={""}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['affectedDiseasesData', 4, 'forHowLong']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.diabetes} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['affectedDiseasesData', 4, 'medicineConsumption']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.diabetes} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Item>
                        <div className="vertical-centered">
                          <input className="affectedDiseasesData" id="otherDisease" type='checkbox' defaultChecked={formData.affectedDiseasesData[5]?.type==='otherDisease'?true:false} onChange={(e) => handleTypeSelection(e, 'affectedDiseasesData', 5, 'otherDisease')} />
                          <label htmlFor="otherDisease"> Other, specify</label>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name={['affectedDiseasesData', 5, 'type']}
                        initialValue={""}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['affectedDiseasesData', 5, 'forHowLong']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.otherDisease} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name={['affectedDiseasesData', 5, 'medicineConsumption']}
                        rules={[
                          {
                            max: 120,
                            message: "Field length can't exceed 120 characters",
                          },
                          {
                            pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                            message: "numbers, characters and (, . _ -) are allowed only",
                          }
                        ]}
                        initialValue={""}
                      >
                        <Input disabled={disableType.otherDisease} />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
              {
                displayComponent.isAffectedDisease&&<hr className="horizontal-seperator"/>
              }
              {/* Affected Diseases Data Part Ends */}
              {/* Relative Affected Diseases Data Part Starts */}
              <Form.Item
                name="isRelativeAffectedDisease"
                label="4. Does any family member have blood pressure/cardiovascular disease/atherosclerosis/brain stroke? "
                labelCol={{ span: 14.5 }}
                wrapperCol={{ span: 7.5 }}
              >
                <Radio.Group value={displayComponent.isRelativeAffectedDisease} style={{ marginLeft: "10px" }} onChange={(e) => handleDisplayResetComponent(e, 'relativeAffectedDiseasesData', 'isRelativeAffectedDisease')}>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <div style={displayComponent.isRelativeAffectedDisease ? { overflow: 'auto hidden', display: 'block' } : { overflow: 'auto hidden', display: 'none' }}>
                <table className="responsive-table">
                  <colgroup>
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '55%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '15%' }} />
                  </colgroup>
                  <thead>
                    <tr style={{ textAlign: 'center' }}>
                      <td style={{textAlign:'left'}}>Family Members</td>
                      <td >
                        <Row>
                          <Col span={5}>
                            Blood Pressure
                          </Col>
                          <Col span={6}>
                            Cardiovascular disease
                          </Col>
                          <Col span={5}>
                            Atherosclerosis
                          </Col>
                          <Col span={5}>
                            Brain stroke
                          </Col>
                          <Col span={3}>
                            Diabetes
                          </Col>
                        </Row>
                      </td>
                      <td >Any other, specify</td>
                      <td >Number of brothers/sisters/children suffering from any of these disease</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Item
                        >
                          Father
                        </Form.Item>
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 0, 'member']}
                          initialValue={"father"}
                          style={{ width: '20px', display: 'none' }}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td className="text-center">
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 0, 'diseases']}
                          initialValue={[]}
                        >
                          <Checkbox.Group style={{ display: 'block' }}>
                            <Row>

                              <Col span={5}>
                                <Checkbox value="blood pressure" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={6}>
                                <Checkbox value="cardiovascular disease" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={5}>
                                <Checkbox value="atherosclerosis" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={5}>
                                <Checkbox value="brain stroke" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={3}>
                                <Checkbox value="diabetes" style={{ lineHeight: '32px' }} />

                              </Col>

                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 0, 'otherSpecified']}
                          rules={[
                            {
                              max: 120,
                              message: "Field length can't exceed 120 characters",
                            },
                            {
                              pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                              message: "numbers, characters and (, . _ -) are allowed only",
                            }
                          ]}
                          initialValue={""}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 0, 'numberOfMember']}
                          rules={[
                            {
                              pattern: new RegExp("^[0-9]*$"),
                              message: "Only numbers are allowed",
                            },
                            {min: 1, max: 2, message: "Can't exceed 2 digit" },
                          ]}
                          initialValue={""}
                        >
                          <Input disabled />
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Item
                        >
                          Mother
                        </Form.Item>
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 1, 'member']}
                          initialValue={"mother"}
                          style={{ width: '20px', display: 'none' }}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td className="text-center">
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 1, 'diseases']}
                          initialValue={[]}
                        >
                          <Checkbox.Group style={{ display: 'block' }}>
                            <Row>

                              <Col span={5}>
                                <Checkbox value="blood pressure" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={6}>
                                <Checkbox value="cardiovascular disease" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={5}>
                                <Checkbox value="atherosclerosis" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={5}>
                                <Checkbox value="brain stroke" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={3}>
                                <Checkbox value="diabetes" style={{ lineHeight: '32px' }} />

                              </Col>

                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 1, 'otherSpecified']}
                          rules={[
                            {
                              max: 120,
                              message: "Field length can't exceed 120 characters",
                            },
                            {
                              pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                              message: "numbers, characters and (, . _ -) are allowed only",
                            }
                          ]}
                          initialValue={""}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 1, 'numberOfMember']}
                          rules={[
                            {
                              pattern: new RegExp("^[0-9]*$"),
                              message: "Only numbers are allowed",
                            },
                            {min: 1, max: 2, message: "Can't exceed 2 digit" },
                          ]}
                          initialValue={""}
                        >
                          <Input disabled />
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Item
                        >
                          Brother
                        </Form.Item>
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 2, 'member']}
                          initialValue={"brother"}
                          style={{ width: '20px', display: 'none' }}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td className="text-center">
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 2, 'diseases']}
                          initialValue={[]}
                        >
                          <Checkbox.Group style={{ display: 'block' }}>
                            <Row>

                              <Col span={5}>
                                <Checkbox value="blood pressure" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={6}>
                                <Checkbox value="cardiovascular disease" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={5}>
                                <Checkbox value="atherosclerosis" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={5}>
                                <Checkbox value="brain stroke" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={3}>
                                <Checkbox value="diabetes" style={{ lineHeight: '32px' }} />

                              </Col>

                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 2, 'otherSpecified']}
                          rules={[
                            {
                              max: 120,
                              message: "Field length can't exceed 120 characters",
                            },
                            {
                              pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                              message: "numbers, characters and (, . _ -) are allowed only",
                            }
                          ]}
                          initialValue={""}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 2, 'numberOfMember']}
                          rules={[
                            {
                              pattern: new RegExp("^[0-9]*$"),
                              message: "Only numbers are allowed",
                            },
                            {min: 1, max: 2, message: "Can't exceed 2 digit" },
                          ]}
                          initialValue={""}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Item
                        >
                          Sister
                        </Form.Item>
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 3, 'member']}
                          initialValue={"sister"}
                          style={{ width: '20px', display: 'none' }}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td className="text-center">
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 3, 'diseases']}
                          initialValue={[]}
                        >
                          <Checkbox.Group style={{ display: 'block' }}>
                            <Row>

                              <Col span={5}>
                                <Checkbox value="blood pressure" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={6}>
                                <Checkbox value="cardiovascular disease" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={5}>
                                <Checkbox value="atherosclerosis" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={5}>
                                <Checkbox value="brain stroke" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={3}>
                                <Checkbox value="diabetes" style={{ lineHeight: '32px' }} />

                              </Col>

                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 3, 'otherSpecified']}
                          rules={[
                            {
                              max: 120,
                              message: "Field length can't exceed 120 characters",
                            },
                            {
                              pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                              message: "numbers, characters and (, . _ -) are allowed only",
                            }
                          ]}
                          initialValue={""}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 3, 'numberOfMember']}
                          rules={[
                            {
                              pattern: new RegExp("^[0-9]*$"),
                              message: "Only numbers are allowed",
                            },
                            {min: 1, max: 2, message: "Can't exceed 2 digit" },
                          ]}
                          initialValue={""}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Item
                        >
                          Children
                        </Form.Item>
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 4, 'member']}
                          initialValue={"children"}
                          style={{ width: '20px', display: 'none' }}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td className="text-center">
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 4, 'diseases']}
                          initialValue={[]}
                        >
                          <Checkbox.Group style={{ display: 'block' }}>
                            <Row>

                              <Col span={5}>
                                <Checkbox value="blood pressure" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={6}>
                                <Checkbox value="cardiovascular disease" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={5}>
                                <Checkbox value="atherosclerosis" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={5}>
                                <Checkbox value="brain stroke" style={{ lineHeight: '32px' }} />

                              </Col>
                              <Col span={3}>
                                <Checkbox value="diabetes" style={{ lineHeight: '32px' }} />

                              </Col>

                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 4, 'otherSpecified']}
                          rules={[
                            {
                              max: 120,
                              message: "Field length can't exceed 120 characters",
                            },
                            {
                              pattern: new RegExp("^[ 0-9a-zA-z,_.-]*$"),
                              message: "numbers, characters and (, . _ -) are allowed only",
                            }
                          ]}
                          initialValue={""}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          name={['relativeAffectedDiseasesData', 4, 'numberOfMember']}
                          rules={[
                            {
                              pattern: new RegExp("^[0-9]*$"),
                              message: "Only numbers are allowed",
                            },
                            {min: 1, max: 2, message: "Can't exceed 2 digit" },
                          ]}
                          initialValue={""}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Relative Affected Diseases Data Part Ends */} 
              </div>
              <div className="form-block-colored-container">
              <h2 className="form-block-header">SNPs</h2>
              <RadioGroupConponent label={'1. rs12654264'} name={'rs12654264'}/>
              <RadioGroupConponent label={'2. rs12916'} name={'rs12916'}/>
              <RadioGroupConponent label={'3. rs3846662'} name={'rs3846662'}/>
              <RadioGroupConponent label={'4. rs10474433'} name={'rs10474433'}/>
              <RadioGroupConponent label={'5. rs17244841'} name={'rs17244841'}/>
              <RadioGroupConponent label={'6. rs17238540'} name={'rs17238540'}/>
              <RadioGroupConponent label={'7. rs3761740'} name={'rs3761740'}/>
              <RadioGroupConponent label={'8. rs17671591'} name={'rs17671591'}/>
              <RadioGroupConponent label={'9. rs147043821'} name={'rs147043821'}/>
              <RadioGroupConponent label={'10. rs193026499'} name={'rs193026499'}/>
              </div>
            </Form>
          </div>
        </div>
      ) : isRequestingData ? (
        <div className="custom-spin-container">
          <Spin size="large" />
          <h5>Loading Data. Please Wait</h5>
        </div>
      ) : (
        <div
          style={{
            height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Empty description={"No Record Found"}></Empty>
        </div>
      )}
      <FooterFixedBottom />
    </>
  );
};
export default UpdateBloodSamples;
