import BaseRequestBody from '../../../models/BaseRequestBody';

export default class ReqGetSampleById extends  BaseRequestBody {
    id=0

    constructor(data){
        super();
        this.update(data);
        if(data.id)this.id=Number(data.id);
    }
}