import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { calculateAge } from '../../assets/function/CustomFunction';
import BloodSamplesAction from '../../stores/blood-samples/BloodSamplesAction';
import { selectRequesting } from '../../stores/special/requesting/RequestingSelector';
import ComponentTable from './ComponentTable';
import EditOutlined from '@ant-design/icons/EditOutlined'
import { Button } from 'antd';
import { AppRoutes } from '../../assets/constants/routes';
import {Link} from 'react-router-dom';
import ModalDeleteConfirmation from '../modals/ModalDeleteConfirmation';
import BloodSamplesReport from '../components/pdf/BloodSamplesReportPdf';
import { isMobile } from 'react-device-detect';
import moment from 'moment';


const BloodSamplesTable = (props) => {
    const isRequesting = useSelector((state) => selectRequesting(state, [BloodSamplesAction.REQUEST_GET_ALL_SAMPLES]))
    return (
        <div style={isMobile?{margin:'0 5px'}:{margin:'0 10px'}}>
            <ComponentTable pageSize={props.pageSize} current={props.current} sort={props.sort} sortOrder={props.sortOrder} handleTableChange={props.handleTableChange} total={props.samplesList.total||0} isRequesting={isRequesting} dataList={props.samplesList.bloodSamples}  columns={columns} />
        </div>
    );
};

export default BloodSamplesTable;

const columns = [
    {
        title: 'Sample Id',
        dataIndex: 'sampleId',
        width: '5%',
        align:'center',
        sorter: true
    },
    {
        title: 'Cross Id',
        dataIndex: 'id',
        width: '6%',
        align:'center',
        sorter: true
    },
    {
        title: 'Date',
        dataIndex: 'createdDate',
        render: (text, record) => (
            <div>{ record.createdDate && moment(record.createdDate, 'YYYY-MM-DD').format('DD/MM/YYYY') }</div>
        ),
        sorter: (a, b) => a.createdDate && b.createdDate && a.createdDate.localeCompare(b.createdDate),
        align: 'center',
        width: '7%'
    },
    {
        title: 'Participant Name',
        render: (text, record) => (
            <div> {record.name} </div>
        ),
        // sorter: (a, b) => (a.user.title).localeCompare(b.project.title),
        width: '18%',
        align:'center'
    },
    {
        title: 'Gender',
        dataIndex: 'gender',
        render: (text, record) => (
            <div style={{textTransform:'capitalize'}}> {record.gender} </div>
        ),
        width: '7%',
        align:'center'
    },
    {
        title: 'Age',
        dataIndex: 'birthDate',
        render: (text, record) => (
            <div> {record.birthDate&&calculateAge(record.birthDate)} </div>
        ),
        sorter: (a, b) => a.birthDate && b.birthDate && a.birthDate.localeCompare(b.birthDate),
        width: '12%',
        align:'center'
    },
    {
        title: 'Glucose',
        children:[
            {
                title: 'mmolar',
                dataIndex: 'glucoseResultMmol',
                width: '7%',
                align:'center',
                sorter: (a, b) => a.glucoseResultMmol - b.glucoseResultMmol
            }
        ],
        align:'center'
    },
    {
        title: 'Lipid Profile',
        children:[
            {
                title: 'TG (mg/dl)',
                dataIndex: 'tgResultMgdl',
                width: '7%',
                align:'center',
                sorter: (a, b) => a.tgResultMgdl - b.tgResultMgdl
                
            },
            {
                title: 'Cholesterol (mg/dl)',
                dataIndex: 'cholesterolResultMgdl',
                width: '7%',
                align:'center',
                sorter: (a, b) => a.cholesterolResultMgdl - b.cholesterolResultMgdl
            },
            {
                title: 'HDL (mg/dl)',
                dataIndex: 'hdlResultMgdl',
                width: '7%',
                align:'center',
                sorter: (a, b) => a.hdlResultMgdl - b.hdlResultMgdl
            },
            {
                title: 'LDL (mg/dl)',
                dataIndex: 'ldlResultMgdl',
                width: '7%',
                align:'center',
                sorter: (a, b) => a.ldlResultMgdl - b.ldlResultMgdl
            }
        ],
        align:'center'
    },
    {
        title: 'Actions',
        render: (_, record)=>(
            <div key={record.id}>
                <BloodSamplesReport collectionDate={record.collectionDate ? moment(record.collectionDate, 'YYYY-MM-DD').format('DD/MM/YYYY'):''} testDate={record.testDate?moment(record.testDate, 'YYYY-MM-DD').format('DD/MM/YYYY'):''} key={record.id} filteredName={record.name?record.name.replace('.',''):''} age={record.birthDate ? calculateAge(record.birthDate):''} data={record} id={record.id}/>
                <Link
                    to={`${AppRoutes.UPDATE_BLOOD_SAMPLES}/${record.id}`}
                >
                    <Button style={{ borderRadius: '60%', padding:'1px 4px', backgroundColor:'#EBF4FF', marginRight: '3px' }}><span style={{ display: "flex", justifyContent: "center" }}><EditOutlined style={{fontSize:20, color:'#1C72E2'}} /></span></Button>
                </Link>
                <ModalDeleteConfirmation data={record}/>
                {/* onClick={() => loadAudio(data.compressedFilePath, process.env.REACT_APP_COMPRESSED_FILE_BUCKET)} */}
            </div>
        ),
        width: '10%',
        align:'center'
    }
]
