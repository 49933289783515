import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./navigation.css";
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined'
import { Popover, Button } from "antd";
import { makeSelectUserDetails } from "../../../stores/user/UserDataSelector";
import ResetAction from "../../../stores/special/reset/ResetAction";
import { getCookie, setCookie } from "../../../assets/function/CustomFunction";
import '../../pages/pagestyle.css';
import Logo from '../../../assets/images/nib-logo.png'
import { FileExcelOutlined, LoadingOutlined } from "@ant-design/icons";
import BloodSamplesAction from "../../../stores/blood-samples/BloodSamplesAction";
import { makeSelectExcelFileLink } from "../../../stores/blood-samples/BloodSamplesDataSelector";
import { ApiEndpoint } from "../../../assets/constants/ApiEndpoint";
import { selectRequesting } from "../../../stores/special/requesting/RequestingSelector";
import { selectFinished } from "../../../stores/special/finished/FinishedSelector";
import fs from 'fs'

function TopBar(props) {
  const [downExcelBtnClicked, setDownExcelBtnClicked] = useState(false)
  const userDetails = useSelector((state) => makeSelectUserDetails(state));
  const dispatch = useDispatch();
  const signOut = () => {
    dispatch(ResetAction.resetStorage());
    localStorage.clear();
    setCookie("jwtToken", "");
    window.location.reload();
  };
  const downloadExcel = () => {
    dispatch(BloodSamplesAction._requestGetSamplesExcelFile());
    setDownExcelBtnClicked(true)
  }
  const excelFileLink = useSelector((state) => makeSelectExcelFileLink(state));
  const isRequestingExcelReq = useSelector((state) =>
    selectRequesting(state, [BloodSamplesAction.REQUEST_GET_BLOOD_SAMPLES_EXCEL_FILE])
  );
  const isFinishedExcelReq = useSelector((state) =>
    selectFinished(state, BloodSamplesAction.REQUEST_GET_BLOOD_SAMPLES_EXCEL_FILE)
  );
  useEffect(()=>{
    if(isFinishedExcelReq && excelFileLink && downExcelBtnClicked){
      const link = document.createElement('a');
      link.href = excelFileLink;
      link.setAttribute(
        'download',
        `Samples-${new Date().toLocaleDateString()}.xlsx`,
      );
      link.click();
      link.remove();
      setDownExcelBtnClicked(false)
    }
  },[isFinishedExcelReq, downExcelBtnClicked])
  return (
    <div className="container-app-topbar">
      <div className="brand-block">
        <div className="brand-image">
          <img src={Logo} alt="nib-logo" height="50" />
        </div>
        <div className="brand-title">
          <h3>National Institute of Biotechnology</h3>
          <h5>Data Entry Platform: HMGCR GENE SNP Analysis</h5>
        </div>
      </div>
      <div className="container-right-block">
        <div id='excel-generate' className="excel-file-download" onClick={!isRequestingExcelReq?downloadExcel:function(){}}  >
          <div className="icon">
            <FileExcelOutlined style={isRequestingExcelReq?{fontSize: '24px',display:'none'}:{fontSize: '24px',display:'block'}} />
            <LoadingOutlined style={isRequestingExcelReq?{fontSize: '24px',display:'block'}:{fontSize: '24px',display:'none'}}/>
          </div>
          <div className="title">
            Download <br />Excel File
          </div>
        </div>
        {/* <div id='excel-loading' style={{display:'block', PointerEvent:'none'}} className="excel-file-download" onClick={downloadExcel}>
          <div className="icon">
            <LoadingOutlined style={{fontSize: '24px'}}/>
          </div>
          <div className="title">
            Generating <br />Excel File
          </div>
        </div> */}
        <div>
          <Popover
            placement="bottomRight"
            content={
              <Button className="signout-btn" onClick={() => signOut()}>
                Logout
              </Button>
            }
            trigger="click"
          >
            <div className="pop-over-block">
              <div>
                <p>Logged in as</p>
                <h4 style={{ display: 'flex', alignItems: 'center' }}>{userDetails.userDetails.email} <CaretDownOutlined style={{ fontSize: '20px' }} /></h4>
              </div>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
}

export default withRouter(TopBar);
