import React from 'react'
import './navigation.css';

function Footer(props) {
    return (
        <div className="footer">
              <svg xmlns="http://www.w3.org/2000/svg" width="241.086" height="60.285" viewBox="0 0 241.086 60.285">
  <g id="Group_107" data-name="Group 107" transform="translate(-519 -610)">
    <text id="Powered_by" data-name="Powered by" transform="translate(560 645)" fill="#9f9f9f" font-size="14" font-family="Poppins-Light, Poppins" font-weight="300"><tspan x="-40.572" y="0">Powered by</tspan></text>
    <g id="Group_98" data-name="Group 98" transform="translate(259.238 303.857)">
      <rect id="Rectangle_110" data-name="Rectangle 110" width="39.536" height="39.536" transform="translate(354.004 336.285) rotate(-45)" fill="#fb9b38"/>
      <path id="Path_42" data-name="Path 42" d="M381.961,366.428l-30.143-30.142,30.143-30.143L412.1,336.286Zm-25.77-30.142,25.77,25.769,25.769-25.769-25.769-25.77Z" fill="#4e565c"/>
      <rect id="Rectangle_111" data-name="Rectangle 111" width="18.298" height="18.298" transform="translate(395.154 336.285) rotate(-45)" fill="#4e565c"/>
      <path id="Path_43" data-name="Path 43" d="M398.731,347.995,383.606,332.87l15.125-15.126,15.125,15.126ZM387.978,332.87l10.754,10.752,10.752-10.752-10.752-10.754Z" transform="translate(9.362 3.416)" fill="#fb9b38"/>
      <g id="Group_97" data-name="Group 97" transform="translate(429.448 325.279)">
        <path id="Path_44" data-name="Path 44" d="M412.788,337.854a1.337,1.337,0,0,1-.522-.324,1.545,1.545,0,0,1-.352-.59,3.186,3.186,0,0,1,0-1.789,1.529,1.529,0,0,1,.352-.589,1.325,1.325,0,0,1,.522-.325,1.958,1.958,0,0,1,.636-.1h1.419v.654h-1.31a1.2,1.2,0,0,0-.382.061.738.738,0,0,0-.313.2,1.019,1.019,0,0,0-.211.384,2.442,2.442,0,0,0,0,1.212,1.02,1.02,0,0,0,.211.384.727.727,0,0,0,.313.2,1.2,1.2,0,0,0,.382.061h1.31v.654h-1.419a1.958,1.958,0,0,1-.636-.1" transform="translate(-411.786 -317.035)" fill="#4e565c"/>
        <path id="Path_45" data-name="Path 45" d="M414.484,336.213a2.241,2.241,0,0,1,.126-.8,1.4,1.4,0,0,1,.34-.532,1.266,1.266,0,0,1,.5-.294,2.055,2.055,0,0,1,.621-.091,2.025,2.025,0,0,1,.615.091,1.3,1.3,0,0,1,.507.294,1.426,1.426,0,0,1,.346.532,2.573,2.573,0,0,1,0,1.6,1.427,1.427,0,0,1-.346.532,1.285,1.285,0,0,1-.507.3,2.063,2.063,0,0,1-.615.091,2.094,2.094,0,0,1-.621-.091,1.253,1.253,0,0,1-.5-.3,1.4,1.4,0,0,1-.34-.532,2.234,2.234,0,0,1-.126-.8m1.594,1.1a1.055,1.055,0,0,0,.34-.054.69.69,0,0,0,.282-.18.918.918,0,0,0,.19-.338,2,2,0,0,0,0-1.059.914.914,0,0,0-.19-.339.717.717,0,0,0-.282-.18,1.1,1.1,0,0,0-.682,0,.722.722,0,0,0-.281.18.9.9,0,0,0-.19.339,2,2,0,0,0,0,1.059.9.9,0,0,0,.19.338.694.694,0,0,0,.281.18,1.063,1.063,0,0,0,.342.054" transform="translate(-410.991 -316.93)" fill="#4e565c"/>
        <path id="Path_46" data-name="Path 46" d="M417.329,334.557h1.485a2.239,2.239,0,0,1,.545.063,1.173,1.173,0,0,1,.448.21,1.043,1.043,0,0,1,.3.384,1.369,1.369,0,0,1,.111.581v2.035h-.709V335.8a.624.624,0,0,0-.062-.293.547.547,0,0,0-.17-.19.71.71,0,0,0-.251-.106,1.38,1.38,0,0,0-.308-.034h-.682v2.658h-.709Z" transform="translate(-410.154 -316.911)" fill="#4e565c"/>
        <path id="Path_47" data-name="Path 47" d="M420.056,337.215h1.714a.592.592,0,0,0,.313-.079.292.292,0,0,0,.128-.271.3.3,0,0,0-.128-.284.6.6,0,0,0-.313-.076h-.8a1.676,1.676,0,0,1-.418-.049.761.761,0,0,1-.318-.163.726.726,0,0,1-.2-.3,1.318,1.318,0,0,1-.071-.467,1.28,1.28,0,0,1,.071-.458.716.716,0,0,1,.2-.3.787.787,0,0,1,.318-.161,1.676,1.676,0,0,1,.418-.049h1.806v.616h-1.714a.608.608,0,0,0-.316.079.29.29,0,0,0-.131.271.3.3,0,0,0,.129.285.623.623,0,0,0,.316.075h.795a1.665,1.665,0,0,1,.417.049.778.778,0,0,1,.318.164.752.752,0,0,1,.2.3,1.35,1.35,0,0,1,.07.466,1.3,1.3,0,0,1-.07.458.718.718,0,0,1-.2.3.767.767,0,0,1-.317.161,1.593,1.593,0,0,1-.416.049h-1.807Z" transform="translate(-409.377 -316.911)" fill="#4e565c"/>
        <path id="Path_48" data-name="Path 48" d="M422.611,334.557h.709v2.035a.619.619,0,0,0,.062.293.535.535,0,0,0,.17.19.713.713,0,0,0,.251.107,1.378,1.378,0,0,0,.308.032h.682v-2.658h.709v3.274h-1.485a2.3,2.3,0,0,1-.545-.062,1.192,1.192,0,0,1-.448-.21,1.039,1.039,0,0,1-.3-.386,1.369,1.369,0,0,1-.111-.581Z" transform="translate(-408.598 -316.911)" fill="#4e565c"/>
        <rect id="Rectangle_112" data-name="Rectangle 112" width="0.709" height="4.366" transform="translate(17.56 16.555)" fill="#4e565c"/>
        <path id="Path_49" data-name="Path 49" d="M426.824,335.344h-.546v-.616h.546v-.8h.708v.8h.765v.616h-.765v1.692a.3.3,0,0,0,.137.286.636.636,0,0,0,.328.079h.3v.616H427.9a1.467,1.467,0,0,1-.419-.057.888.888,0,0,1-.344-.185.865.865,0,0,1-.232-.337,1.345,1.345,0,0,1-.084-.513Z" transform="translate(-407.518 -317.097)" fill="#4e565c"/>
        <path id="Path_50" data-name="Path 50" d="M428.259,333.714h.709v.709h-.709Zm0,1.091h.709v3.274h-.709Z" transform="translate(-406.934 -317.159)" fill="#4e565c"/>
        <path id="Path_51" data-name="Path 51" d="M429.313,334.557H430.8a2.25,2.25,0,0,1,.546.063,1.182,1.182,0,0,1,.448.21,1.043,1.043,0,0,1,.3.384,1.369,1.369,0,0,1,.111.581v2.035H431.5V335.8a.623.623,0,0,0-.062-.293.547.547,0,0,0-.17-.19.72.72,0,0,0-.251-.106,1.387,1.387,0,0,0-.308-.034h-.682v2.658h-.709Z" transform="translate(-406.624 -316.911)" fill="#4e565c"/>
        <path id="Path_52" data-name="Path 52" d="M433.6,338.306a.737.737,0,0,0,.406-.106.4.4,0,0,0,.167-.369h-.884a1.718,1.718,0,0,1-.546-.084,1.105,1.105,0,0,1-.447-.278,1.348,1.348,0,0,1-.3-.5,2.685,2.685,0,0,1,0-1.539,1.331,1.331,0,0,1,.3-.5,1.091,1.091,0,0,1,.447-.278,1.72,1.72,0,0,1,.546-.085h1.594v3.274a1.353,1.353,0,0,1-.086.514.868.868,0,0,1-.232.335.891.891,0,0,1-.343.185,1.482,1.482,0,0,1-.421.057h-1.7v-.616Zm.573-1.091v-2.041h-.791a.968.968,0,0,0-.309.049.613.613,0,0,0-.251.167.845.845,0,0,0-.168.313,1.934,1.934,0,0,0,0,.983.863.863,0,0,0,.168.315.6.6,0,0,0,.251.166.97.97,0,0,0,.309.049Z" transform="translate(-405.867 -316.911)" fill="#4e565c"/>
        <path id="Path_53" data-name="Path 53" d="M436.69,336.811a1.326,1.326,0,0,1,.076-.485.759.759,0,0,1,.515-.483,1.33,1.33,0,0,1,.379-.052h1.32a.6.6,0,0,0-.066-.29.528.528,0,0,0-.171-.19.74.74,0,0,0-.249-.1,1.347,1.347,0,0,0-.305-.034h-1.283v-.616h1.375a2.221,2.221,0,0,1,.544.063,1.2,1.2,0,0,1,.448.21,1.038,1.038,0,0,1,.305.382,1.353,1.353,0,0,1,.111.579v2.04h-2.03a1.329,1.329,0,0,1-.379-.052.767.767,0,0,1-.308-.171.821.821,0,0,1-.207-.317,1.311,1.311,0,0,1-.076-.48m.709,0a.363.363,0,0,0,.122.313.47.47,0,0,0,.287.091h1.173V336.4h-1.173a.484.484,0,0,0-.287.087.371.371,0,0,0-.122.322" transform="translate(-404.451 -316.911)" fill="#4e565c"/>
        <path id="Path_54" data-name="Path 54" d="M439.471,334.557h1.485a2.258,2.258,0,0,1,.546.063,1.168,1.168,0,0,1,.447.21,1.03,1.03,0,0,1,.3.384,1.349,1.349,0,0,1,.113.581v2.035h-.709V335.8a.624.624,0,0,0-.063-.293.534.534,0,0,0-.17-.19.7.7,0,0,0-.251-.106,1.37,1.37,0,0,0-.308-.034h-.681v2.658h-.711Z" transform="translate(-403.632 -316.911)" fill="#4e565c"/>
        <path id="Path_55" data-name="Path 55" d="M443.45,338.079a1.709,1.709,0,0,1-.545-.084,1.108,1.108,0,0,1-.448-.278,1.382,1.382,0,0,1-.3-.5,2.715,2.715,0,0,1,0-1.539,1.366,1.366,0,0,1,.3-.5,1.1,1.1,0,0,1,.448-.278,1.71,1.71,0,0,1,.545-.085h.884v-1.091h.709v4.365Zm.884-.616v-2.041h-.791a.96.96,0,0,0-.308.049.6.6,0,0,0-.251.167.847.847,0,0,0-.17.313,1.974,1.974,0,0,0,0,.983.863.863,0,0,0,.17.315.59.59,0,0,0,.251.166.962.962,0,0,0,.308.049Z" transform="translate(-402.875 -317.159)" fill="#4e565c"/>
        <path id="Path_56" data-name="Path 56" d="M447.021,337.3h1.991a.651.651,0,0,0,.366-.1.5.5,0,0,0,0-.722.641.641,0,0,0-.366-.1h-.927a1.954,1.954,0,0,1-.487-.057.921.921,0,0,1-.37-.189.832.832,0,0,1-.234-.346,1.5,1.5,0,0,1-.083-.527,1.512,1.512,0,0,1,.083-.532.828.828,0,0,1,.234-.347.9.9,0,0,1,.37-.185,2.02,2.02,0,0,1,.487-.054h2.1v.654h-1.992a.661.661,0,0,0-.366.1.5.5,0,0,0,0,.724.642.642,0,0,0,.366.1h.928a1.95,1.95,0,0,1,.485.057.913.913,0,0,1,.37.189.838.838,0,0,1,.236.346,1.767,1.767,0,0,1,0,1.061.85.85,0,0,1-.236.344.892.892,0,0,1-.37.185,2.019,2.019,0,0,1-.485.054h-2.1Z" transform="translate(-401.441 -317.035)" fill="#4e565c"/>
        <path id="Path_57" data-name="Path 57" d="M449.841,336.213a2.242,2.242,0,0,1,.126-.8,1.4,1.4,0,0,1,.34-.532,1.257,1.257,0,0,1,.5-.294,2.056,2.056,0,0,1,.621-.091,2.031,2.031,0,0,1,.615.091,1.3,1.3,0,0,1,.507.294,1.426,1.426,0,0,1,.346.532,2.574,2.574,0,0,1,0,1.6,1.426,1.426,0,0,1-.346.532,1.285,1.285,0,0,1-.507.3,2.07,2.07,0,0,1-.615.091,2.094,2.094,0,0,1-.621-.091,1.244,1.244,0,0,1-.5-.3,1.4,1.4,0,0,1-.34-.532,2.235,2.235,0,0,1-.126-.8m1.594,1.1a1.06,1.06,0,0,0,.34-.054.685.685,0,0,0,.281-.18.9.9,0,0,0,.192-.338,2,2,0,0,0,0-1.059.9.9,0,0,0-.192-.339.713.713,0,0,0-.281-.18,1.1,1.1,0,0,0-.682,0,.712.712,0,0,0-.281.18.879.879,0,0,0-.19.339,2,2,0,0,0,0,1.059.882.882,0,0,0,.19.338.685.685,0,0,0,.281.18,1.062,1.062,0,0,0,.342.054" transform="translate(-400.578 -316.93)" fill="#4e565c"/>
        <rect id="Rectangle_113" data-name="Rectangle 113" width="0.709" height="4.366" transform="translate(52.946 16.555)" fill="#4e565c"/>
        <path id="Path_58" data-name="Path 58" d="M453.7,334.557h.709v2.035a.619.619,0,0,0,.062.293.523.523,0,0,0,.17.19.713.713,0,0,0,.251.107,1.379,1.379,0,0,0,.308.032h.682v-2.658h.709v3.274h-1.485a2.3,2.3,0,0,1-.545-.062,1.2,1.2,0,0,1-.448-.21,1.039,1.039,0,0,1-.3-.386,1.369,1.369,0,0,1-.111-.581Z" transform="translate(-399.442 -316.911)" fill="#4e565c"/>
        <path id="Path_59" data-name="Path 59" d="M456.857,335.344h-.546v-.616h.546v-.8h.709v.8h.764v.616h-.764v1.692a.3.3,0,0,0,.136.286.636.636,0,0,0,.328.079h.3v.616h-.394a1.467,1.467,0,0,1-.419-.057.888.888,0,0,1-.344-.185.877.877,0,0,1-.232-.337,1.344,1.344,0,0,1-.084-.513Z" transform="translate(-398.672 -317.097)" fill="#4e565c"/>
        <path id="Path_60" data-name="Path 60" d="M458.292,333.714H459v.709h-.709Zm0,1.091H459v3.274h-.709Z" transform="translate(-398.089 -317.159)" fill="#4e565c"/>
        <path id="Path_61" data-name="Path 61" d="M459.22,336.213a2.271,2.271,0,0,1,.124-.8,1.433,1.433,0,0,1,.34-.532,1.27,1.27,0,0,1,.5-.294,2.154,2.154,0,0,1,1.236,0,1.284,1.284,0,0,1,.506.294,1.426,1.426,0,0,1,.346.532,2.574,2.574,0,0,1,0,1.6,1.426,1.426,0,0,1-.346.532,1.271,1.271,0,0,1-.506.3,2.152,2.152,0,0,1-1.236,0,1.257,1.257,0,0,1-.5-.3,1.433,1.433,0,0,1-.34-.532,2.264,2.264,0,0,1-.124-.8m1.592,1.1a1.063,1.063,0,0,0,.342-.054.686.686,0,0,0,.281-.18.9.9,0,0,0,.192-.338,2.04,2.04,0,0,0,0-1.059.9.9,0,0,0-.192-.339.713.713,0,0,0-.281-.18,1.1,1.1,0,0,0-.682,0,.713.713,0,0,0-.281.18.9.9,0,0,0-.192.339,2.04,2.04,0,0,0,0,1.059.9.9,0,0,0,.192.338.686.686,0,0,0,.281.18,1.06,1.06,0,0,0,.34.054" transform="translate(-397.816 -316.93)" fill="#4e565c"/>
        <path id="Path_62" data-name="Path 62" d="M462.065,334.557h1.485a2.247,2.247,0,0,1,.545.063,1.183,1.183,0,0,1,.448.21,1.044,1.044,0,0,1,.3.384,1.369,1.369,0,0,1,.111.581v2.035h-.709V335.8a.623.623,0,0,0-.062-.293.545.545,0,0,0-.17-.19.721.721,0,0,0-.251-.106,1.387,1.387,0,0,0-.308-.034h-.682v2.658h-.709Z" transform="translate(-396.978 -316.911)" fill="#4e565c"/>
        <path id="Path_63" data-name="Path 63" d="M464.792,337.215h1.714a.6.6,0,0,0,.313-.079.292.292,0,0,0,.128-.271.3.3,0,0,0-.128-.284.607.607,0,0,0-.313-.076h-.8a1.665,1.665,0,0,1-.417-.049.766.766,0,0,1-.32-.163.752.752,0,0,1-.2-.3,1.32,1.32,0,0,1-.071-.467,1.282,1.282,0,0,1,.071-.458.741.741,0,0,1,.2-.3.792.792,0,0,1,.32-.161,1.665,1.665,0,0,1,.417-.049h1.806v.616H465.8a.613.613,0,0,0-.317.079.293.293,0,0,0-.131.271.3.3,0,0,0,.131.285.62.62,0,0,0,.316.075h.795a1.665,1.665,0,0,1,.417.049.793.793,0,0,1,.318.164.735.735,0,0,1,.2.3,1.316,1.316,0,0,1,.071.466,1.3,1.3,0,0,1-.07.458.73.73,0,0,1-.2.3.775.775,0,0,1-.317.161,1.609,1.609,0,0,1-.417.049h-1.806Z" transform="translate(-396.202 -316.911)" fill="#4e565c"/>
        <path id="Path_64" data-name="Path 64" d="M422.415,328.722a3.182,3.182,0,0,0-1.285-.652,6.655,6.655,0,0,0-1.684-.2h-3.214a2.2,2.2,0,0,1-1.267-.359,1.748,1.748,0,0,1,0-2.506,2.246,2.246,0,0,1,1.267-.35h6.9v-2.269h-7.282a6.908,6.908,0,0,0-1.683.189,3.082,3.082,0,0,0-1.285.643,2.906,2.906,0,0,0-.814,1.2,5.233,5.233,0,0,0-.283,1.845,5.169,5.169,0,0,0,.283,1.825,2.9,2.9,0,0,0,.814,1.2,3.174,3.174,0,0,0,1.285.652,6.587,6.587,0,0,0,1.683.2h3.216a2.213,2.213,0,0,1,1.267.359,1.748,1.748,0,0,1,0,2.506,2.249,2.249,0,0,1-1.267.351h-6.9v2.269h7.28a6.981,6.981,0,0,0,1.684-.189,3.106,3.106,0,0,0,1.285-.643,2.908,2.908,0,0,0,.813-1.192,6.079,6.079,0,0,0,0-3.678,2.9,2.9,0,0,0-.813-1.2" transform="translate(-411.786 -320.495)" fill="#fb9b38"/>
        <path id="Path_65" data-name="Path 65" d="M424.929,324.141a3.837,3.837,0,0,0-1.551.964,4.721,4.721,0,0,0-1.05,1.75,9.35,9.35,0,0,0,0,5.333,4.706,4.706,0,0,0,1.05,1.749,3.824,3.824,0,0,0,1.551.964,5.991,5.991,0,0,0,1.891.294h4.218v-2.137h-3.9a3.285,3.285,0,0,1-1.068-.171,2.042,2.042,0,0,1-.871-.576,2.948,2.948,0,0,1-.586-1.087,6.81,6.81,0,0,1,0-3.4,2.927,2.927,0,0,1,.586-1.087,2.046,2.046,0,0,1,.871-.577,3.32,3.32,0,0,1,1.068-.17h3.9v-2.137H426.82a5.99,5.99,0,0,0-1.891.293" transform="translate(-408.795 -320.065)" fill="#fb9b38"/>
        <rect id="Rectangle_114" data-name="Rectangle 114" width="2.459" height="15.13" transform="translate(23.793)" fill="#fb9b38"/>
        <path id="Path_66" data-name="Path 66" d="M445.385,320.926h-12.1v2.27h4.728v12.861h2.648V323.2h4.728Z" transform="translate(-405.456 -320.926)" fill="#fb9b38"/>
        <path id="Path_67" data-name="Path 67" d="M447.51,323.848a6,6,0,0,0-1.891.293,3.846,3.846,0,0,0-1.551.964,4.739,4.739,0,0,0-1.05,1.75,9.38,9.38,0,0,0,0,5.333,4.723,4.723,0,0,0,1.05,1.749,3.819,3.819,0,0,0,1.551.964,6,6,0,0,0,1.891.294h4.218v-2.137h-3.9a3.617,3.617,0,0,1-.926-.114,2.044,2.044,0,0,1-.786-.4,2.349,2.349,0,0,1-.6-.756,3.914,3.914,0,0,1-.359-1.192h6.562v-6.751H447.51Zm2.019,4.6h-4.364a3.918,3.918,0,0,1,.359-1.173,2.349,2.349,0,0,1,.6-.756,2.159,2.159,0,0,1,.786-.406,3.353,3.353,0,0,1,.926-.123h1.7Z" transform="translate(-402.702 -320.065)" fill="#fb9b38"/>
        <path id="Path_68" data-name="Path 68" d="M453.962,324.141a3.85,3.85,0,0,0-1.552.964,4.719,4.719,0,0,0-1.049,1.75,9.349,9.349,0,0,0,0,5.333,4.7,4.7,0,0,0,1.049,1.749,3.836,3.836,0,0,0,1.552.964,5.991,5.991,0,0,0,1.891.294h4.218v-2.137h-3.9a3.293,3.293,0,0,1-1.069-.171,2.046,2.046,0,0,1-.87-.576,2.928,2.928,0,0,1-.586-1.087,6.81,6.81,0,0,1,0-3.4,2.908,2.908,0,0,1,.586-1.087,2.05,2.05,0,0,1,.87-.577,3.328,3.328,0,0,1,1.069-.17h3.9v-2.137h-4.218a5.99,5.99,0,0,0-1.891.293" transform="translate(-400.245 -320.065)" fill="#fb9b38"/>
        <path id="Path_69" data-name="Path 69" d="M468.834,326.987a3.561,3.561,0,0,0-1.05-1.333,4.1,4.1,0,0,0-1.551-.728,7.924,7.924,0,0,0-1.891-.217h-2.685v-3.783H459.2v15.13h2.46v-9.211h2.364a4.861,4.861,0,0,1,1.068.113,2.5,2.5,0,0,1,.871.369,1.854,1.854,0,0,1,.585.663,2.125,2.125,0,0,1,.219,1.011v7.055h2.458V329a4.7,4.7,0,0,0-.388-2.014" transform="translate(-397.822 -320.926)" fill="#fb9b38"/>
      </g>
    </g>
  </g>
</svg>
        </div>
    )
}


export default Footer;