import EffectUtility from '../../utils/EffectUtility';
import BaseResponse from "../../models/BaseResponse";
import BaseRequestParams from "../../models/BaseRequestParams";
import {ApiEndpoint} from "../../assets/constants/ApiEndpoint";


export default class DbBackupEffect {

    static async _requestGetDriveLink(body) {
        const endPoint = ApiEndpoint.db_backup.getDriveLink;
        return EffectUtility._postToModel(BaseResponse, endPoint, new BaseRequestParams(body));
    }

  
}
