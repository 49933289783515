import BaseRequestBody from '../../../models/BaseRequestBody';

export default class ReqUpdateSamples extends  BaseRequestBody {
    id=0;
    sampleId = 0;
    name = '';
    birthDate= '';
    glucoseResultMgdl= 0;
    tgResultMgdl= 0;
    cholesterolResultMgdl= 0;
    hdlResultMgdl= 0;
    testDate= '';
    collectedBy= '';
    examinedBy= '';
    collectionDate= '';
    profession= '';
    address= '';
    placeOfBirth= '';
    mobileNumber= '';
    systolicBloodPressure= 0;
    diastolicBloodPressure= 0;
    height= 0;
    weight= 0;
    bmi= 0;
    gender = '';
    rs12654264=0;
    rs12916=0;
    rs3846662=0;
    rs10474433=0;
    rs17244841=0;
    rs17238540=0;
    rs3761740=0;
    rs17671591=0;
    rs147043821=0;
    rs193026499=0;
    isDrugAddicted= null;
    drugAddictionData= [
        {
            type:'',
            otherSpecified:'',
            forHowLong:'',
            dailyAmount:''
        }
    ];
    isPerformPhysicalActivities= null;
    physicalActivitiesData= [
        {
            type:'',
            otherSpecified:'',
            forHowLong:'',
            dailyAmount:''
        }
    ];
    isAffectedDisease= null;
    affectedDiseasesData= [
        {
            type:'',
            otherSpecified:'',
            forHowLong:'',
            medicineConsumption:''
        }
    ];
    isRelativeAffectedDisease= null;
    relativeAffectedDiseasesData= [
        {
            member:'',
            diseases:[],
            otherSpecified:'',
            numberOfMember:''
        }
    ];
    constructor(data){
        super();
        this.update(data);
        if(data.id)this.id=Number(data.id)
        if(data.sampleId)this.sampleId=Number(data.sampleId)
        if(data.glucoseResultMgdl)this.glucoseResultMgdl=Number(data.glucoseResultMgdl)
        if(data.tgResultMgdl)this.tgResultMgdl=Number(data.tgResultMgdl)
        if(data.cholesterolResultMgdl)this.cholesterolResultMgdl=Number(data.cholesterolResultMgdl)
        if(data.hdlResultMgdl)this.hdlResultMgdl=Number(data.hdlResultMgdl)
        if(data.systolicBloodPressure)this.systolicBloodPressure=Number(data.systolicBloodPressure)
        if(data.diastolicBloodPressure)this.diastolicBloodPressure=Number(data.diastolicBloodPressure)
        if(data.height)this.height=Number(data.height)
        if(data.weight)this.weight=Number(data.weight)
        if(data.bmi)this.bmi=Number(data.bmi)
        if(data.testDate)this.testDate=data.testDate.format('YYYY-MM-DD');
        if(data.collectionDate)this.collectionDate=data.collectionDate.format('YYYY-MM-DD');
        if(data.birthDate)this.birthDate=data.birthDate.format('YYYY-MM-DD');
    }
}