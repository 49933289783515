import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { Page, View, Document, StyleSheet, PDFDownloadLink, Text, Image } from '@react-pdf/renderer';
import FilePdfOutlined from '@ant-design/icons/FilePdfOutlined';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import Loading3QuartersOutlined from '@ant-design/icons/Loading3QuartersOutlined';
import NotFoundPage from '../../pages/NotFoundPage';
import BrandImage from '../../../assets/images/nib-logo.png'
import SponsorImage from '../../../assets/images/powered-by_scitechbd.png'



const BloodSamplesReport = (props) => {
  const [ready, setReady] = useState(false)
  const [blobUrl, setBlobUrl] = useState(null);
  useEffect(() => {
    if (blobUrl !== null && blobUrl) {
      var link = document.createElement("a");
      link.href = blobUrl;
      link.download = `Report_${props.filteredName}_${props.data.id}_${props.data.sampleId}.pdf`;
      link.click();
      // console.log("open");
      // window.open(blobUrl, '_blank');
      setReady(false);
      setBlobUrl(null);
    }
  }, [blobUrl])
  try {
    return !ready ? (
      <Button key={props.data.id} onClick={() => setReady(true)} style={{ borderRadius: '60%', padding: '1px 4px', backgroundColor: '#EBEBFF', marginRight: '3px' }}><span style={{ display: "flex", justifyContent: "center" }}><FilePdfOutlined style={{ fontSize: 20, color: '#27267D' }} /></span></Button>
    ) : (
      props.data && (
        <PDFDownloadLink
          key={props.data.id}
          document={<PDFDocument data={props.data} key={props.data.id} age={props.age} collectionDate={props.collectionDate} testDate={props.testDate} />}
          fileName={`Report_${props.filteredName}_${props.data.id}_${props.data.sampleId}`}
        >
          {
            ({ blob, url, loading, error }) =>
            (
              loading ?
                <Button key={props.data.id} style={{ borderRadius: '60%', padding: '1px 4px', backgroundColor: '#21A764', marginRight: '3px' }}>
                  <span style={{ display: "flex", justifyContent: "center" }}><Loading3QuartersOutlined style={{ fontSize: 20, color: '#FFF' }} /></span>
                </Button>
                : (
                  // isMobile ?
                  //   <Button key={props.data.id} style={{ borderRadius: '60%', padding: '1px 4px', backgroundColor: '#21A764', marginRight: '3px' }}>
                  //     <span style={{ display: "flex", justifyContent: "center" }}><DownloadOutlined style={{ fontSize: 20, color: '#FFF' }} /></span>
                  //   </Button>
                  //   :
                    url !== null && setBlobUrl(url)
                )
            )
          }
        </PDFDownloadLink>
      )
    )
  } catch (error) {
    return <NotFoundPage>{
      console.log("PDF Error", error)}</NotFoundPage>
  }
}

export default BloodSamplesReport



function PDFDocument(props) {
  try {
    return (
      <Document>
        <Page style={styles.body}>
          <Image
            style={styles.brandLogo}
            src={BrandImage}
          />
          <View style={styles.titleBlock}>
            <Text style={styles.title}>
              National Institute of Biotechnology
            </Text>
            <Text style={styles.subTitle}>
              HMGCR Gene SNP Analysis
            </Text>
          </View>
          <View style={styles.flexItem}>
            <View>
              <Text style={styles.blockTitle}>
                Participant Information
              </Text>
              <View style={styles.flexItem}>
                <View>

                  <Text style={styles.blockText}>
                    {'ID '}
                  </Text>
                  <Text style={styles.blockText}>
                    {'Cross ID '}
                  </Text>
                  <Text style={styles.blockText}>
                    {'Participant '}
                  </Text>
                  <Text style={styles.blockText}>
                    {'Gender '}
                  </Text>
                  <Text style={styles.blockText}>
                    {'Age '}
                  </Text>
                  <Text style={styles.blockText}>
                    {'Test Date '}
                  </Text>

                </View>
                <View>
                  <Text style={styles.blockText}>
                    {' :'} {props.data.sampleId}
                  </Text>
                  <Text style={styles.blockText}>
                    {' :'} {props.data.id}
                  </Text>
                  <Text style={styles.blockText}>
                    {' :'} {props.data.name}
                  </Text>
                  <Text style={styles.blockText}>
                    {' :'} {props.data.gender}
                  </Text>
                  <Text style={styles.blockText}>
                    {' :'} {props.age}
                  </Text>
                  <Text style={styles.blockText}>
                    {' :'} {props.testDate ? props.testDate : ''}
                  </Text>
                </View>
              </View>
            </View>
            <View>
              <Text style={styles.blockTitle}>
                Collection Information
              </Text>
              <View style={styles.flexItem}>
                <View>

                  <Text style={styles.blockText}>
                    {'Collection Date '}
                  </Text>
                  <Text style={styles.blockText}>
                    {'Collected By '}
                  </Text>
                </View>
                <View>
                  <Text style={styles.blockText}>
                    {' :'} {props.collectionDate ? props.collectionDate : ''}
                  </Text>
                  <Text style={styles.blockText}>
                    {' :'} {props.data.collectedBy ? props.data.collectedBy : ''}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {
            props.data.glucoseResultMmol !== 0 &&
            <View style={[styles.table], styles.marginTopTable}>
              <View style={styles.tableTopRow}>
                <Text style={styles.tableHeaderCell}>Glucose</Text>
              </View>

              <View style={styles.tableSecondRow}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, styles.boldText]}>Type of Test</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, styles.boldText]}>Result</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, styles.boldText]}>Range</Text>
                </View>
              </View>
              <View style={styles.tableDataRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Glucose</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{props.data.glucoseResultMmol} mmol/L</Text>
                </View>
                <View style={styles.tableColRange}>
                  <Text style={styles.tableCellRange}>{'Fasting blood glucose: 3.9 - 5.6 mmol/L; Random Blood glucose: 4.4 - 7.8 mmol/L '}</Text>
                </View>
              </View>
            </View>
          }
          {
            (props.data.tgResultMgdl !== 0 || props.data.cholesterolResultMgdl !== 0 || props.data.hdlResultMgdl !== 0) &&
            <View style={styles.table}>
              <View style={styles.tableTopRow}>
                <Text style={styles.tableHeaderCell}>Lipid Profile</Text>
              </View>

              <View style={styles.tableSecondRow}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, styles.boldText]}>Type of Test</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, styles.boldText]}>Result</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, styles.boldText]}>Range</Text>
                </View>
              </View>
              {
                props.data.tgResultMgdl !== 0 &&
                <View style={styles.tableDataRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>TG</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{props.data.tgResultMgdl} mg/dL</Text>
                  </View>
                  <View style={styles.tableColRange}>
                    <Text style={styles.tableCellRange}>{'Normal: Less than 150 mg/dL; Borderline high: 150 to 199 mg/dL; High: 200 to 499 mg/dL '}</Text>
                  </View>
                </View>
              }
              {
                props.data.cholesterolResultMgdl !== 0 &&
                <View style={styles.tableDataRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Cholesterol</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{props.data.cholesterolResultMgdl} mg/dL</Text>
                  </View>
                  <View style={styles.tableColRange}>
                    <Text style={styles.tableCellRange}>{'Normal: Less than 200 mg/dL; Borderline high: 200 to 239 mg/dL; High: At or above 240 mg/dL '}</Text>
                  </View>
                </View>
              }
              {
                props.data.hdlResultMgdl !== 0 &&
                <View style={styles.tableDataRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>HDL</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{props.data.hdlResultMgdl} mg/dL</Text>
                  </View>
                  <View style={styles.tableColRange}>
                    <Text style={styles.tableCellRange}>Desirable: 60 mg/dL or above; Risk: Less than 40 mg/dL</Text>
                  </View>
                </View>
              }
              {
                props.data.ldlResultMgdl !== 0 &&
                <View style={styles.tableDataRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>LDL</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{props.data.ldlResultMgdl} mg/dL</Text>
                  </View>
                  <View style={styles.tableColRange}>
                    <Text style={styles.tableCellRange}>{'Normal: Less than 100 mg/dL; Borderline high: 100 to 159 mg/dL; High: 160 mg/dL and higher '}</Text>
                  </View>
                </View>
              }

            </View>
          }
          <View style={styles.remarks}>
            <Text style={styles.remarksText}>
              * This diagnosis test is intended to research only.
            </Text>
          </View>
          <View style={styles.checkedBy}>
            <Text style={styles.checkedByText}>
              Checked by
            </Text>
          </View>
          <Image
            style={styles.sponsorLogo}
            src={SponsorImage}
          />
        </Page>
      </Document>
    )
  } catch (error) {
    return <NotFoundPage>{
      console.log("PDF Document Error", error)}</NotFoundPage>
  }
}
// ---------- removed from for crash issue ------------//
// font-family: 'Poppins', sans-serif!important;
// Font.register({
//   family: 'Poppins, sans-serif',
//   fonts: [{ src: Poppins }]
// });

const styles = StyleSheet.create({
  body: {
    paddingTop: 10,
    paddingBottom: 20,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 14,
    color: '#27267D'
  },
  marginTopTable: {
    marginTop: 30,
    marginBottom: 20
  },
  subTitle: {
    fontSize: 12,
    color: 'gray'
  },
  titleBlock: {
    textAlign: 'center',
    marginBottom: '20px'
  },
  brandLogo: {
    marginVertical: 15,
    marginHorizontal: 240,
    height: 50,
    width: 50
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  flexItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  blockTitle: {
    color: '#27267D',
    fontSize: 14,
    borderBottomWidth: 1,
    borderBottomColor: '#27267D',
    marginBottom: 5
    // textDecoration:'underline',
    // textDecorationColor: '#27267D',
  },
  floatLeft: {
    float: 'left'
  },
  floadRight: {
    display: 'float',
    float: 'right'
  },
  blockText: {
    fontSize: 10,
    lineHeight: 1.3,
    textTransform: 'capitalize',
  },
  table: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'left',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#edeef0'
  },
  tableHeader: {
    backgroundColor: '#C7D9F2'
  },
  tableData: {},
  tableCol: {
    borderStyle: 'solid',
    width: '28%',
    textAlign: 'left'
  },
  tableColRange: {
    borderStyle: 'solid',
    width: '44%',
    textAlign: 'left'
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableSecondRow: {
    flexDirection: 'row',
    backgroundColor: '#edeef0',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#edeef0'
  },
  tableDataRow: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    color: '#000',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#edeef0'
  },
  tableTopRow: {
    backgroundColor: '#2B2A7A',
    textAlign: 'left',
    paddingLeft: 10
  },
  tableHeaderCell: {
    fontSize: 12,
    margin: 5,
    color: '#FFF'
  },
  tableCell: {
    fontSize: 10,
    margin: 5,
    paddingLeft: 10,
    color: '#000'
  },
  tableCellRange: {
    fontSize: 10,
    margin: 5,
    paddingLeft: 10,
    color: '#000'
  },
  boldText: {
    fontSize: 11,
    fontWeight: 'bold',
    color: '#2B2A7A'
  },
  checkedBy: {
    position: 'absolute',
    marginHorizontal: 400,
    bottom: 150,
    width: '30%',
    borderTopWidth: 1,
    borderTopColor: '#000',
    textAlign: 'center'
  },
  checkedByText: {
    fontSize: 10,
    paddingTop: 10
  },
  remarks: {
    paddingHorizontal: 35,
    position: 'absolute',
    bottom: 250,
    textAlign: 'left'
  },
  remarksText: {
    fontSize: 10,
    paddingTop: 10
  },
  sponsorLogo: {
    position: 'absolute',
    bottom: 70,
    marginHorizontal: 230,
    height: 30,
    width: 120
  }
});