import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import ErrorReducer from './special/error/ErrorReducer';
import RequestingReducer from './special/requesting/RequestingReducer';
import ToastsReducer from './special/toasts/ToastsReducer';
import FinishedReducer from "./special/finished/FinishedReducer";
import MiscReducer from './misc/MiscReducer';
import UserReducer from './user/UserReducer';
import BloodSamplesReducer from './blood-samples/BloodSamplesReducer';
import DbBackupReducer from './db-backup/DbBackupReducer';

export default (history) => {
    const reducerMap = {
        error: ErrorReducer.reducer,
        requesting: RequestingReducer.reducer,
        finished: FinishedReducer.reducer,
        router: connectRouter(history),

        toasts: new ToastsReducer().reducer,
        misc: new MiscReducer().reducer,

        user : new UserReducer().reducer,
        samples : new BloodSamplesReducer().reducer,
        dbbackup : new DbBackupReducer().reducer
       
    };

    return combineReducers(reducerMap);
};
