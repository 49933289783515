import ActionUtility from '../../utils/ActionUtility';
import BloodSamplesEffect from './BloodSamplesEffect';

export default class BloodSamplesAction {

    static REQUEST_GET_ALL_SAMPLES = 'REQUEST_GET_ALL_SAMPLES';
    static REQUEST_GET_ALL_SAMPLES_FINISHED = 'REQUEST_GET_ALL_SAMPLES_FINISHED';

    static REQUEST_GET_SAMPLES_OVERVIEW = 'REQUEST_GET_SAMPLES_OVERVIEW';
    static REQUEST_GET_SAMPLES_OVERVIEW_FINISHED = 'REQUEST_GET_SAMPLES_OVERVIEW_FINISHED';

    static REQUEST_CREATE_BLOOD_SAMPLES = 'REQUEST_CREATE_BLOOD_SAMPLES';
    static REQUEST_CREATE_BLOOD_SAMPLES_FINISHED = 'REQUEST_CREATE_BLOOD_SAMPLES_FINISHED';

    static REQUEST_UPDATE_BLOOD_SAMPLES = 'REQUEST_UPDATE_BLOOD_SAMPLES';
    static REQUEST_UPDATE_BLOOD_SAMPLES_FINISHED = 'REQUEST_UPDATE_BLOOD_SAMPLES_FINISHED';

    static REQUEST_GET_BLOOD_SAMPLE_BY_ID = 'REQUEST_GET_BLOOD_SAMPLE_BY_ID';
    static REQUEST_GET_BLOOD_SAMPLE_BY_ID_FINISHED = 'REQUEST_GET_BLOOD_SAMPLE_BY_ID_FINISHED';
    
    static REQUEST_DELETE_BLOOD_SAMPLES = 'REQUEST_DELETE_BLOOD_SAMPLES';
    static REQUEST_DELETE_BLOOD_SAMPLES_FINISHED = 'REQUEST_DELETE_BLOOD_SAMPLES_FINISHED';

    static REQUEST_GET_BLOOD_SAMPLES_EXCEL_FILE = 'REQUEST_GET_BLOOD_SAMPLES_EXCEL_FILE';
    static REQUEST_GET_BLOOD_SAMPLES_EXCEL_FILE_FINISHED = 'REQUEST_GET_BLOOD_SAMPLES_EXCEL_FILE_FINISHED';

    static _requestGetAllSamples(request) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(dispatch, BloodSamplesAction.REQUEST_GET_ALL_SAMPLES, BloodSamplesEffect._requestGetAllSamples, request )
        }
    }

    static _requestGetAllSampleById(request) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(dispatch, BloodSamplesAction.REQUEST_GET_BLOOD_SAMPLE_BY_ID, BloodSamplesEffect._requestGetAllSampleById, request )
        }
    }

    static _requestCreateBloodSamples(request) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(dispatch, BloodSamplesAction.REQUEST_CREATE_BLOOD_SAMPLES, BloodSamplesEffect._requestCreateBloodSamples, request )
        }
    }

    static _requestUpdateBloodSamples(request) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(dispatch, BloodSamplesAction.REQUEST_UPDATE_BLOOD_SAMPLES, BloodSamplesEffect._requestUpdateBloodSamples, request )
        }
    }

    static _requestDeleteBloodSamples(request) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(dispatch, BloodSamplesAction.REQUEST_DELETE_BLOOD_SAMPLES, BloodSamplesEffect._requestDeleteBloodSamples, request )
        }
    }

    static _requestGetSamplesDataOverview(request) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(dispatch, BloodSamplesAction.REQUEST_GET_SAMPLES_OVERVIEW, BloodSamplesEffect._requestGetSamplesDataOverview, request )
        }
    }

    static _requestGetSamplesExcelFile(request) {
        return async (dispatch, getState) => {
            await ActionUtility._createThunkEffect(dispatch, BloodSamplesAction.REQUEST_GET_BLOOD_SAMPLES_EXCEL_FILE, BloodSamplesEffect._requestGetSamplesExcelFile, request )
        }
    }


}