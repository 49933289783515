import React from 'react'
import { Table } from 'antd'
import { isMobile } from 'react-device-detect'
import './table-style.css';
import { getCookie } from '../../assets/function/CustomFunction';

export default function ComponentTable(props) {
    props.columns.map((column, i)=>{
        if(column.dataIndex === props.sort){
            props.columns[i]={...column, defaultSortOrder: props.sortOrder}
        } 
    })
    return (
        <Table
            rowClassName={(record, index) =>
                index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
            }
            showHeader={true}
            bordered={true}
            columns={props.columns}
            dataSource={props.dataList}
            onChange={props.handleTableChange}
            pagination={
                props.pagination === false
                    ? false
                    : {
                        pageSize: props.pageSize,
                        current: props.current,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30', '50'],
                        total: props.total,
                        defaultCurrent: Number(getCookie('current')) || 1,
                        defaultPageSize: Number(getCookie('pageSize')) || 50
                      }
            }
            size='middle'
            rowKey={(data) => data.id}
            loading={props.isRequesting}
            scroll={{ x: !isMobile ? '' : 'max-content' }}
        />
    )
}
