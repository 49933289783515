import BaseReducer from '../../utils/BaseReducer';
import DbBackupAction from './DbBackupAction';


export default class DbBackupReducer extends BaseReducer {

    initialState = {
        driveLink: null
    };

    [DbBackupAction.REQUEST_GET_DRIVE_LINK_FINISHED](state, action) {
        return {
            ...state,
            driveLink: action.payload,
        };
    }


}