import { createSelector } from 'reselect';
 
export const makeSelectSamplesList = createSelector(
    state => state.samples.samplesList ,
    (allData ) =>(allData ? allData.data[0] : [])
)

export const makeSelectSamplesOverview = createSelector(
    state => state.samples.samplesOverview ,
    (allData ) =>(allData ? allData.data[0] : [])
)

export const makeSelectSampleById = createSelector(
    state => state.samples.sampleById ,
    (allData ) =>(allData ? allData.data[0] : [])
)

export const makeSelectExcelFileLink = createSelector(
    state => state.samples.excelFileLink ,
    (allData ) =>{
        if(allData?.dataObject){
            const url = window.URL.createObjectURL(new Blob([allData.dataObject]));
            return url;
        }
        else{
            return '';
        }
    }
)