import React from "react";
import { Form, Input, Button } from "antd";
import LockOutlined from "@ant-design/icons/LockOutlined";
import MailOutlined from "@ant-design/icons/MailOutlined";
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import '../components/components.css';
import { useDispatch, useSelector } from "react-redux";
import ReqGetUserDetails from "../../stores/user/request/ReqGetUserDetails";
import { selectRequesting } from "../../stores/special/requesting/RequestingSelector";
import { selectFinished } from "../../stores/special/finished/FinishedSelector";
import TopNavBar from "../components/navigation/TopBar";
import FooterFixedBottom from "../components/navigation/FooterFixedBottom";
import DbBackupAction from "../../stores/db-backup/DbBackupAction";
import { makeSelectBackupDriveLink } from "../../stores/db-backup/DbBackupDataSelector";


const DatabaseBackupPage = (props) => {
    const dispatch = useDispatch();
    const isRequestingDbBackup = useSelector((state) =>
        selectRequesting(state, [DbBackupAction.REQUEST_GET_DRIVE_LINK])
    );
    const onFinish = (values) => {
        dispatch(DbBackupAction._requestGetDriveLink(new ReqGetUserDetails(values)));
    };
    const backupDriveData = useSelector(state => makeSelectBackupDriveLink(state));
    return (
        <div className="page-basic-container">
            <TopNavBar />
            <div className="db-backup-login-container">
                <div className="login-form">
                    <div className="centered-block ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="62" height="54.75" viewBox="0 0 62 54.75">
                            <g id="Group_156" data-name="Group 156" transform="translate(-846 0)">
                                <path id="Path_109" data-name="Path 109" d="M265.91,91.383l11.024,11.674,11.023-11.674L284.4,88.022l-5.018,5.314V71.214h-4.9V93.336l-5.018-5.314Z" transform="translate(600.065 -71.214)" fill="#27267d" />
                                <path id="Path_110" data-name="Path 110" d="M163.1,141.717H150.269v3.263h10.912l9.97,17.907h-17.5l-.863,3.187a2.927,2.927,0,0,1-2.664,2.036H138.987a2.926,2.926,0,0,1-2.663-2.036l-.865-3.187H117.967l9.969-17.907h10.912v-3.263H126.017l-12.459,22.378v18.235a4.9,4.9,0,0,0,4.895,4.895h52.21a4.9,4.9,0,0,0,4.895-4.895V164.094Z" transform="translate(732.442 -132.472)" fill="#27267d" />
                            </g>
                        </svg>
                        <h3>NIBRESDATA Database Backup</h3>
                        {
                            !backupDriveData?.gDriveLink &&
                            <h5>Please verify your identity to get download link</h5>
                        }
                    </div>
                    {
                        !backupDriveData?.gDriveLink &&
                        <Form onFinish={onFinish} validateTrigger={"onBlur"}>
                        <h4 style={{ textAlign: 'center' }}>Enter your verification credentials</h4>
                        <Form.Item
                            name={"email"}
                            rules={[
                                { type: "email", message: "Please enter valid email" },
                                { required: true, message: "Please enter your email" },
                            ]}
                        >
                            <Input
                                prefix={<MailOutlined className="site-form-item-icon" />}
                                placeholder=" Email"
                            />
                        </Form.Item>
                        <Form.Item
                            name={"password"}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your password",
                                },
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                placeholder=" Password"
                            />
                        </Form.Item>
                        <Form.Item className="signIn-block">
                            <Button type="primary" htmlType="submit" loading={isRequestingDbBackup}>
                               Get Download Link
                            </Button>
                        </Form.Item>
                    </Form>
                    }
                    {
                       backupDriveData?.gDriveLink &&
                       <div className="backup-download-block">
                           <h3>Download the latest backup</h3>
                            <span className="custom-green-button download-backup-button">
                            <Button type="primary" >
                                <a target="_blank"  href={backupDriveData.gDriveLink} download>
                                <DownloadOutlined /> Download Backup File
                                </a>
                            </Button>
                            </span>
                       </div> 
                    }
                   
                </div>
            </div>
            <FooterFixedBottom />
        </div>
    );
};

export default DatabaseBackupPage;