import BaseReducer from '../../utils/BaseReducer';
import BloodSamplesAction from './BloodSamplesAction';


export default class BloodSamplesReducer extends BaseReducer {

    initialState = {
        samplesList: null ,
        samplesOverview: null,
        sampleById: null,
        excelFileLink: null
    };

    [BloodSamplesAction.REQUEST_GET_ALL_SAMPLES_FINISHED](state, action) {
        return {
            ...state,
            samplesList: action.payload,
        };
    }

    [BloodSamplesAction.REQUEST_GET_SAMPLES_OVERVIEW_FINISHED](state, action) {
        return {
            ...state,
            samplesOverview: action.payload,
        };
    }

    [BloodSamplesAction.REQUEST_GET_BLOOD_SAMPLE_BY_ID_FINISHED](state, action) {
        return {
            ...state,
            sampleById: action.payload,
        };
    }

    [BloodSamplesAction.REQUEST_GET_BLOOD_SAMPLES_EXCEL_FILE_FINISHED](state, action) {
        return {
            ...state,
            excelFileLink: action.payload,
        };
    }
}