import React, { useEffect } from "react";
import CreateBloodSamples from "../forms/CreateBloodSamples";

const SaveBloodSamplesPage = (props) => {
  return (
      <div>
          <CreateBloodSamples/>
      </div>
  );
};

export default SaveBloodSamplesPage;
