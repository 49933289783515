import { AppRoutes } from '../../assets/constants/routes'
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel'
import MiscAction from '../misc/MiscAction'
import BloodSamplesAction from './BloodSamplesAction'

const BloodSamplesPostEffect = () => (store) => (next) => (action) => {
    const isError = action.payload instanceof HttpErrorResponseModel
    if (!isError) {
        switch (action.type) {
            case BloodSamplesAction.REQUEST_CREATE_BLOOD_SAMPLES_FINISHED:
                next(MiscAction._showModalSuccess('Form Saved',AppRoutes.CREATE_BLOOD_SAMPLES,'check'))
                break
            case BloodSamplesAction.REQUEST_UPDATE_BLOOD_SAMPLES_FINISHED:
                next(MiscAction._showModalSuccess('Form Updated',AppRoutes.DEFAULT,'check'))
                break
            case BloodSamplesAction.REQUEST_DELETE_BLOOD_SAMPLES_FINISHED:
                next(MiscAction._showModalSuccess('Entry Deleted',AppRoutes.DEFAULT,'trash alternate outline'))
                break
            default:
                break
        }
    }
    return next(action)
}
export default BloodSamplesPostEffect;
